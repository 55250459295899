import React, { useEffect } from "react";
import "./Algosignal.scss";
import signalimage from '../../assets/images/live-signal.jpg';
import { Link } from "react-router-dom";

function Algosignal() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <div className="signalmain">
        <div className="signaltable table-fixed">
          <div className="table-cell">
            <img
              className="signalimg"
              src={signalimage}
              alt="Live Signal"
            />
          </div>
          <div className="table-cell table-cell__text">
            <h3>Supporting Signal</h3>
            <p>
              Experience the power of Supporting signal trading! Navigate markets
              confidently with real-time insights. Elevate your trading strategy
              with instant updates. Explore opportunities and make informed
              decisions. Join us for a dynamic trading journey!
            </p>
            <Link to="/supportsoftware">
              <button className="signal-button" onClick={scrollToTop}>Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Algosignal;
