import React from "react";
import "./Dashboard_Popup.scss";
import LIFE_Image from "../../assets/images/life.jpg";
import Deposite_Image from "../../assets/images/Deposite.jpg";
import Stock_adv from "../../assets/images/stock_adv.png";
import { Link } from "react-router-dom";

function Dashboard_Popup() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };
  return (
    <>
      <div className="popup">
        <div className="popup-inner login-box">
          <h2 className="dashboardpopuph2">Our Services</h2>
          <div className="image-sections">
            <div className="image-section">
              <Link to="/lifeinsurance">
                <img src={LIFE_Image} alt="Service-2" />
                <h4>LI</h4>
              </Link>
            </div>
            <div className="image-section">
              <Link to="/comingsoon">
                <img src={Deposite_Image} alt="Service-3" />
                <h4>Deposit</h4>
              </Link>
            </div>
            <div className="image-section" onClick={scrollToTop}>
              <Link to="/tradingsoftware">
                <img src={Stock_adv} alt="Service-4" />
                <h4>Trading Software</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard_Popup;
