import React from 'react'
import Tradingpackage from '../trading_service_package/Tradingpackage'
import Statscounter from '../trading_stats_counter/Statscounter'
import Tradingservice from '../trading_services/Tradingservice'
import Tradinghero from '../trading_heropage/Tradinghero'
import Tradingabout from '../trading_about/Tradingabout'
import Tradingtestimonials from '../trading_testimonials/Tradingtestimonials'
import Tradingfooter from '../trading_footer/Tradingfooter'
import Tradingcopyright from '../trading_copyright/Tradingcopyright'
import Tradingnavbar from '../trading_navbar/Tradingnavbar'

function TradingSoftware() {
  return (
    <>
    <Tradingnavbar/>
    <Tradinghero/>
    <Tradingpackage/>
    <Statscounter/>
    <Tradingservice/>
    <Tradingabout/>
    <Tradingtestimonials/>
    <Tradingfooter/>
    <Tradingcopyright/>
    </>
  )
}

export default TradingSoftware