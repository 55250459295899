import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./EditProfile.scss";

const cookies = new Cookies();

function EditProfile() {
  const [isSaving, setIsSaving] = useState(false); 
  const [showThankYou, setShowThankYou] = useState(false); 
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    mobile_number: "",
    email_address: "",
    aadhaar_image: null,
    pan_image: null,
    marital_status: "",
    gender: "",
    nominee_type: "",
    nominee_name: "",
    profile_image: null,
    bank_name: "",
    bank_account: "",
    ifsc_code: "",
  });

  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true); // Set saving state to true

    const postData = new FormData();
    for (let key in formData) {
      postData.append(key, formData[key]);
    }

    postData.append("username", cookies.get("username"));
    postData.append("user_id", cookies.get("id"));

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/customerdetails.php",
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      // Handle success response, e.g., show a success message
      setShowThankYou(true); // Show thank you popup on successful submission
      setIsSaving(false); // Set saving state back to false after submission
    } catch (error) {
      console.error("Error:", error.message);
      // Handle error, e.g., show an error message to the user
      setIsSaving(false); // Set saving state back to false on error
    }
  };

  return (
    <div className="container-xl px-4 mt-4">
      <div className="card mb-4">
        <div className="card-header bg-primary text-white">
          <h4 className="mb-0 editprohead">Edit Profile</h4>
        </div>
        <div className="card-body">
          <div className="text-center mb-4">
            <img
              className="img-account-profile rounded-circle mb-2"
              src="http://bootdey.com/img/Content/avatar/avatar1.png"
              alt=""
              style={{ width: "150px", height: "150px" }}
            />
            <div className="small font-italic text-muted mb-4">
              PNG no larger than 1 MB
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputFirstName">
                  First Name
                </label>
                <input
                  className="form-control"
                  id="inputFirstName"
                  name="first_name"
                  type="text"
                  placeholder="Enter your first name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputLastName">
                  Last Name
                </label>
                <input
                  className="form-control"
                  id="inputLastName"
                  name="last_name"
                  type="text"
                  placeholder="Enter your last name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputBirth">
                  Date of Birth
                </label>
                <input
                  className="form-control"
                  id="inputBirth"
                  name="birth_date"
                  type="date"
                  placeholder="Enter your date of birth"
                  value={formData.birth_date}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputNumber">
                  Mobile Number
                </label>
                <input
                  className="form-control"
                  id="inputNumber"
                  name="mobile_number"
                  type="number"
                  placeholder="Enter your mobile number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="small mb-1" htmlFor="inputEmailAddress">
                Email Address
              </label>
              <input
                className="form-control"
                id="inputEmailAddress"
                name="email_address"
                type="email"
                placeholder="Enter your email address"
                value={formData.email_address}
                onChange={handleChange}
              />
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputAadhaarImage">
                  Upload Aadhaar Card Image (PNG)
                </label>
                <input
                  className="form-control"
                  id="inputAadhaarImage"
                  name="aadhaar_image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputPanImage">
                  Upload PAN Card Image (PNG)
                </label>
                <input
                  className="form-control"
                  id="inputPanImage"
                  name="pan_image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputStatus">
                  Marital Status
                </label>
                <select
                  className="form-select"
                  id="inputStatus"
                  name="marital_status"
                  aria-label="Select Marital Status"
                  value={formData.marital_status}
                  onChange={handleChange}
                >
                  <option value="">Select Marital Status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputGender">
                  Gender
                </label>
                <select
                  className="form-select"
                  id="inputGender"
                  name="gender"
                  aria-label="Select Gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputNomineeType">
                  Nominee Type
                </label>
                <select
                  className="form-select"
                  id="inputNomineeType"
                  name="nominee_type"
                  aria-label="Select Nominee Type"
                  value={formData.nominee_type}
                  onChange={handleChange}
                >
                  <option value="">Select Nominee Type</option>
                  <option value="parent">Parent</option>
                  <option value="spouse">Spouse</option>
                  <option value="child">Child</option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputNomineeName">
                  Nominee Name
                </label>
                <input
                  className="form-control"
                  id="inputNomineeName"
                  name="nominee_name"
                  type="text"
                  placeholder="Enter nominee name"
                  value={formData.nominee_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputBankName">
                  Bank Name
                </label>
                <select
                  className="form-control"
                  id="inputBankName"
                  name="bank_name"
                  value={formData.bank_name}
                  onChange={handleChange}
                >
                  <option value="">Select a bank</option>
                  <option value="Axis Bank Ltd.">Axis Bank Ltd.</option>
                  <option value="Bandhan Bank Ltd.">Bandhan Bank Ltd.</option>
                  <option value="CSB Bank Limited">CSB Bank Limited</option>
                  <option value="City Union Bank Ltd.">
                    City Union Bank Ltd.
                  </option>
                  <option value="DCB Bank Ltd.">DCB Bank Ltd.</option>
                  <option value="Dhanlaxmi Bank Ltd.">
                    Dhanlaxmi Bank Ltd.
                  </option>
                  <option value="Federal Bank Ltd.">Federal Bank Ltd.</option>
                  <option value="HDFC Bank Ltd.">HDFC Bank Ltd.</option>
                  <option value="ICICI Bank Ltd.">ICICI Bank Ltd.</option>
                  <option value="IndusInd Bank Ltd.">IndusInd Bank Ltd.</option>
                  <option value="IDFC FIRST Bank Limited">
                    IDFC FIRST Bank Limited
                  </option>
                  <option value="Jammu & Kashmir Bank Ltd.">
                    Jammu & Kashmir Bank Ltd.
                  </option>
                  <option value="Karnataka Bank Ltd.">
                    Karnataka Bank Ltd.
                  </option>
                  <option value="Karur Vysya Bank Ltd.">
                    Karur Vysya Bank Ltd.
                  </option>
                  <option value="Kotak Mahindra Bank Ltd.">
                    Kotak Mahindra Bank Ltd.
                  </option>
                  <option value="Nainital bank Ltd.">Nainital bank Ltd.</option>
                  <option value="RBL Bank Ltd.">RBL Bank Ltd.</option>
                  <option value="South Indian Bank Ltd.">
                    South Indian Bank Ltd.
                  </option>
                  <option value="Tamilnad Mercantile Bank Ltd.">
                    Tamilnad Mercantile Bank Ltd.
                  </option>
                  <option value="YES Bank Ltd.">YES Bank Ltd.</option>
                  <option value="IDBI Bank Limited">IDBI Bank Limited</option>
                  <option value="Bank of Baroda">Bank of Baroda</option>
                  <option value="Bank of India">Bank of India</option>
                  <option value="Bank of Maharashtra">
                    Bank of Maharashtra
                  </option>
                  <option value="Canara Bank">Canara Bank</option>
                  <option value="Central Bank of India">
                    Central Bank of India
                  </option>
                  <option value="Indian Bank">Indian Bank</option>
                  <option value="Indian Overseas Bank">
                    Indian Overseas Bank
                  </option>
                  <option value="Punjab & Sind Bank">Punjab & Sind Bank</option>
                  <option value="Punjab National Bank">
                    Punjab National Bank
                  </option>
                  <option value="State Bank of India">
                    State Bank of India
                  </option>
                  <option value="UCO Bank">UCO Bank</option>
                  <option value="Union Bank of India">
                    Union Bank of India
                  </option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputBankAccount">
                  Bank Account
                </label>
                <input
                  className="form-control"
                  id="inputBankAccount"
                  name="bank_account"
                  type="text"
                  placeholder="Enter bank account"
                  value={formData.bank_account}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputIFSCCode">
                  IFSC Code
                </label>
                <input
                  className="form-control"
                  id="inputIFSCCode"
                  name="ifsc_code"
                  type="text"
                  placeholder="Enter IFSC code"
                  value={formData.ifsc_code}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputProfileImage">
                  Choose Profile Image (PNG)
                </label>
                <input
                  className="form-control"
                  id="inputProfileImage"
                  name="profile_image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <button className="btn btn-primary" type="submit" disabled={isSaving}>
              {isSaving ? "Saving..." : "Details"}
            </button>
          </form>
        </div>
      </div>
    {/* Thank you popup */}
    {showThankYou && (
        <div className="edit-popup">
          <div className="edit-popup-card">
            <h2>Thank you!</h2>
            <p>Your data has been successfully inserted.</p>
            <button className="edit-btn edit-btn-primary" onClick={() => setShowThankYou(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProfile;