import React from "react";
import "./Algosupport.css";
import tradingview from '../../assets/images/tradingview.jpg';
import chartink from '../../assets/images/chartink.png';
import metatrading from '../../assets/images/metatrader.jpeg';
import ninjatrader from '../../assets/images/ninjatrader.jpg';
import excel from '../../assets/images/excel.jpg';
import Gsheet from '../../assets/images/gsheet.jpg';
import Python from '../../assets/images/python.png';
import amibroker from '../../assets/images/amibroker.png';

function Algosupport() {

  const cards = [
    { id: 1, title: 'Trading View', imgSrc: tradingview },
    { id: 2, title: 'Chart Ink', imgSrc: chartink },
    { id: 3, title: 'Meta Trading', imgSrc: metatrading },
    { id: 4, title: 'Ninja Trader', imgSrc: ninjatrader },
    { id: 5, title: 'Excel', imgSrc: excel },
    { id: 6, title: 'G-Sheet', imgSrc: Gsheet },
    { id: 7, title: 'Python', imgSrc: Python },
    { id: 8, title: 'AmiBroker', imgSrc: amibroker }
  ];

  return (
    <div className="trading-support-app">
      <h1>Supporting Software for Algorithmic Trading</h1>
      <h4>Connecting Analysis to Execution</h4>
      <p>
        Supporting software, in the context of algorithmic trading, is a software component or system that takes trading signals generated by a charting platform or a trading strategy and translates them into real-time orders that are sent to a brokerage for execution. Essentially, it acts as a bridge between a trader's analytical environment (where signals are generated) and the trading environment (where orders are executed).
      </p>
      <div className="trading-card-container">
        {cards.map(card => (
          <div key={card.id} className="trading-card">
            <img src={card.imgSrc} alt={card.title} className="trading-card-image" />
            <h3 className="trading-card-title">{card.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Algosupport;
