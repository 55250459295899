import React, { useEffect } from "react";
import "./Tradingservice.scss";
import Scanner from '../../assets/images/Stock-Scanner.png';
import algotrading from '../../assets/images/algotrading.png';
import Stockadv from '../../assets/images/Stockadv.png';
import Signals from '../../assets/images/Signals.png';
import { Link } from "react-router-dom";

function Tradingservice() {
    

  const scrollToServicePackage = () => {
    const servicePackageSection = document.getElementById("service-package");
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <h1 className="services-header" id="trading-service"> Our Services</h1>
      <div className="card-list">
        <article className="card">
          <figure className="card-image">
            <img src={Scanner} className="scannerimage" alt="Scanner" />
          </figure>
          <div className="trade-card-header">
            <h3>Stock Scanner</h3>
            <Link to='/comingsoon'><button className="icon-button">Explore</button></Link>
          </div>
          <p className="card-description">Explore our powerful scanner tool to find the best stocks.</p>
        </article>

        <article className="card" onClick={scrollToTop}>
          <figure className="algo-card-image">
            <img src={algotrading} className="algotradingimage" alt="Algo Trading" />
          </figure>
          <div className="trade-card-header">
            <h3>Algo Trading</h3>
            <Link to='/algotrading'><button className="icon-button">Explore</button></Link>
          </div>
          <p className="card-description">Leverage algorithmic trading to automate your strategies.</p>
        </article>

        <article className="card">
          <figure className="adv-card-image">
            <img src={Stockadv} className="Stockadv" alt="Stock Advisory" />
          </figure>
          <div className="trade-card-header">
            <h3>Stock Advisory</h3>
            <button className="icon-button" onClick={scrollToServicePackage}>Explore</button>
          </div>
          <p className="card-description">Get expert advice to make informed stock investments.</p>
        </article>

        <article className="card">
          <figure className="tra-card-image">
            <img src={Signals} className="trasignals" alt="Trading Signals" />
          </figure>
          <div className="trade-card-header">
            <h3>Trading Signals</h3>
            <Link to='/comingsoon' className="Link-button"><button className="icon-button">Explore</button></Link>
          </div>
          <p className="card-description">Receive trading signals to time your trades perfectly.</p>
        </article>
      </div>
    </>
  );
}

export default Tradingservice;
