import React, { useState, useEffect } from 'react';
import './Algopricing.css';

function Algopricing() {
  const [billingCycle, setBillingCycle] = useState('monthly'); // Default to monthly billing


  const pricingPlans = {
    monthly: { price: 4999, originalPrice: 4999, discount: null },
    halfYearly: { price: 19999, originalPrice: 29998, discount: "33% off" },
    yearly: { price: 34999, originalPrice: 59998, discount: "42% off" },
  };

  const plans = [
    {
      name: 'Monthly',
      features: [
        'Automated Strategies',
        'Customization Strategies',
        'Paper Trade',
        'Strategy Watchlist',
        'Order Execution',
        'Check Live MTM',
        'Manage Live Positions',
        'Risk Management',
        'Real-Time Analytics',
        '6+ Tools Integration',
        'Access unlimited times',
        'Premium Support',
      ],
      pricing: pricingPlans.monthly,
    },
    {
      name: 'Half Yearly',
      features: [
        'Automated Strategies',
        'Customization Strategies',
        'Paper Trade',
        'Strategy Watchlist',
        'Order Execution',
        'Check Live MTM',
        'Manage Live Positions',
        'Risk Management',
        'Real-Time Analytics',
        '6+ Tools Integration',
        'Access unlimited times',
        'Premium Support',
      ],
      pricing: pricingPlans.halfYearly,
    },
    {
      name: 'Yearly',
      features: [
        'Automated Strategies',
        'Customization Strategies',
        'Paper Trade',
        'Strategy Watchlist',
        'Order Execution',
        'Check Live MTM',
        'Manage Live Positions',
        'Risk Management',
        'Real-Time Analytics',
        '6+ Tools Integration',
        'Access unlimited times',
        'Premium Support',
      ],
      pricing: pricingPlans.yearly,
      class: 'center-card', // Add a class for centering
    },
  ];

  const scrollToServicePackage = () => {
    const servicePackageSection = document.getElementById('algo-package');
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <h1 className="Price-heading algo-price-heading algotradeservice" id="algo-package">
        Algotrading Pricing
      </h1>
      <div className="Price-container algopricingcon" >
        {plans.map((plan, index) => (
          <div
            className={`Price-card ${plan.class || ''}`}
            key={index}
          >
            <span className="Price-planName pricetag">{plan.name}</span>
            <h1 className="Price-price" >
              {plan.pricing.originalPrice > plan.pricing.price && (
                <span className="Price-original">
                  ₹{plan.pricing.originalPrice.toLocaleString()}
                </span>
              )}
              ₹{plan.pricing.price.toLocaleString()}
              <span className="Price-small"> / {billingCycle}</span>
              {plan.pricing.discount && (
                <span className="Price-discount">
                  {plan.pricing.discount}
                </span>
              )}
            </h1>
            <ul className="Price-ul">
              {plan.features.map((feature, index) => (
                <li key={index} className="algo-Price-li">
                  {feature}
                </li>
              ))}
            </ul>
            <div className="algo-buttons-package">
              <button className="Algobutton">
                Buy
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Algopricing;
