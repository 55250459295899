import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileMenu.scss";
import profile from "../../assets/images/profile.png";
import editprofile from "../../assets/images/edit-profile.png";
import inbox from "../../assets/images/receive-mail.png";
import setting from "../../assets/images/settings.png";
import help from "../../assets/images/help.png";
import logout from "../../assets/images/check-out.png";
import { Link } from "react-router-dom";
import axios from "axios";

function ProfileMenu({ isVisible, onClose }) {
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      console.log("Attempting logout...");
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/logout.php?logout=true"
      );
      console.log("Logout response:", response.data);
      if (response.data.message === "Logout successful bye") {
        // After successful logout, clear cookies and redirect to login page
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        navigate("/login");
      } else {
        console.error("Unexpected logout response:", response.data);
      }
    } catch (error) {
      console.error("Error occurred during logout:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <>
      {isVisible && <div className="overlay" onClick={onClose} />}
      <div ref={menuRef} className={`menu ${isVisible ? "active" : ""}`}>
        <ul>
          <li>
            <img src={profile} className="profileimg" alt="Profile" />
            <Link to="/myprofile">My profile</Link>
          </li>
          <li>
            <img src={editprofile} className="profileimg" alt="Edit Profile" />
            <Link to="/editprofile">Edit profile</Link>
          </li>
          <li>
            <img src={inbox} className="profileimg" alt="Inbox" />
            <Link to="/comingsoon">Reports</Link>
          </li>
          <li>
            <img src={setting} className="profileimg" alt="Setting" />
            <Link to="/comingsoon">Setting</Link>
          </li>
          <li>
            <img src={help} className="profileimg" alt="Help" />
            <Link to="/comingsoon">Help</Link>
          </li>
          <li>
            <img src={logout} className="profileimg" alt="Logout" />
            <Link onClick={handleLogout}>Logout</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default ProfileMenu;
