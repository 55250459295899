import React, { useState } from "react";
import "./Tradinghero.scss";
import axios from "axios";
import Cookies from "universal-cookie";
import herostock from "../../assets/images/stock-hero.jpg";

function Tradinghero() {
  const cookies = new Cookies();
  const userId = cookies.get("id"); // Get user ID from cookies
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    segment: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
  const [showPopup, setShowPopup] = useState(false); // Track popup visibility
  const [errorMessage, setErrorMessage] = useState(""); // Track error messages

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "name") {
      // Allow only alphabets and spaces for the "name" field
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === "number") {
      // Allow only numbers for the "number" field
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all form fields are filled
    if (!formData.name || !formData.number || !formData.email || !formData.segment) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    // Additional validation checks
    if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      setErrorMessage("Name should only contain alphabets.");
      return;
    }

    if (!/^\d*$/.test(formData.number)) {
      setErrorMessage("Number should only contain digits.");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `https://www.upholic.in/Upholic_Api/freetrial.php?user_id=${userId}`,
        formData
      );
      console.log(response.data);
      setIsSubmitted(true); // Update submission status on success
      setShowPopup(true); // Show popup on success
      setFormData({ // Reset form data after successful submission
        name: "",
        number: "",
        email: "",
        segment: "",
      });

      // Reset isSubmitted after a delay to ensure button text changes properly
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000); // Reset after 3 seconds (adjust as needed)
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const scrollToServicePackage = () => {
    const servicePackageSection = document.getElementById("service-package");
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <header className="mainHeading">
        <div className="mainHeading__content">
          <article className="mainHeading__text">
            <p className="mainHeading__preTitle">Trade Blitz </p>
            <h2 className="mainHeading__title">
              Master the Market with Expert Algorithms and Trading Signals
            </h2>
            <p className="mainHeading__description">
              Unlock the full potential of your investments with our
              cutting-edge algorithmic trading strategies and real-time trading
              signals.
            </p>
            {/* <button className="cta">Send Enquiry</button> */}
          </article>

          <figure className="mainHeading__image">
            <img src={herostock} alt="hero-stock" />
          </figure>

          <form className="mainHeading__form" onSubmit={handleSubmit}>
            <h4 className="form_header">
              Book your Free Trial & Start Profit Booking
            </h4>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type="tel"
              id="number"
              name="number"
              placeholder="Number"
              value={formData.number}
              onChange={handleInputChange}
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <select
              id="segment"
              name="segment"
              value={formData.segment}
              onChange={handleInputChange}
            >
              <option value="">Select Segment</option>
              <option value="Stock Service">
                Stock Service - Cash, Future, Option
              </option>
              <option value="Nifty/Bank Nifty Service">
                Nifty/Bank Nifty Service - Option Future
              </option>
              <option value="Advance Trading Plan">
                Advance Trading Plan (ATP) Service
              </option>
              <option value="Commodity Service">Commodity Service</option>
            </select>
            <button type="submit" className={isSubmitted ? "submitted" : ""}>
              {isSubmitted ? "Submitted" : "Submit"}
            </button>
          </form>
        </div>
      </header>
      {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error messages */}
      {showPopup && (
        <div className="algoser-popup">
          <div className="algoser-popup-content">
            <h2>Thank You!</h2>
            <p>Thank you for showing interest in our service. We will get back to you soon.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Tradinghero;