import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Popup.scss";
import PMS_Image from "../../assets/images/PMS.jpg";
import LIFE_Image from "../../assets/images/life.jpg";
import Deposite_Image from "../../assets/images/Deposite.jpg";
import Stock_adv from "../../assets/images/stock_adv.png";

function Popup() {
  // Define an array of service objects
  const services = [
    { name: "PMS", image: PMS_Image, route: "/signup" },
    { name: "LI", image: LIFE_Image, route: "/signup" },
    { name: "Deposit", image: Deposite_Image, route: "/signup" },
    { name: "Stock_advisory", image: Stock_adv, route: "/signup" },
    // Add more services as needed
  ];


  return (
    <div className="popup">
      <div className="popup-inner login-box">
        <h2 className="homepopuph2">Our Services</h2>
        <div className="image-sections">
          {services.map((service, index) => (
            <div className="image-section" key={index}>
              {/* Use Link to navigate to signup page with service name as query parameter */}
              <Link to={`${service.route}?service=${service.name}`}>
                <img
                  src={service.image}
                  alt={`Service ${index + 1}`}
                  className="Image_link"
                />
                <h4 className="homepopuph4">{service.name}</h4>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Popup;
