import React, { useState } from "react";
import axios from "axios";
import "./Call_Back.scss";

function Call_Back() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [buttonText, setButtonText] = useState("Submit Now");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate input based on field name
    if (name === "name") {
      // Check if value contains only alphabetic characters and spaces
      if (/^[A-Za-z\s]*$/.test(value) || value === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      return;
    }

    // Validate mobile number field (allow only numbers and limit to 10 digits)
    if (name === "mobile") {
      if (/^\d{0,10}$/.test(value) || value === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      return;
    }

    // For other fields (email, message), update formData directly
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled and mobile is exactly 10 digits
    if (
      !formData.name ||
      !formData.email ||
      !formData.mobile ||
      !formData.message
    ) {
      setButtonText("Submit Now");
      alert("Please fill all fields");
      return;
    }

    if (formData.mobile.length !== 10) {
      setButtonText("Submit Now");
      alert("Mobile number must be 10 digits");
      return;
    }

    axios
      .post("https://www.upholic.in/Upholic_Api/requestcall.php", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setButtonText("Submitted");
        // Optionally, reset the form
        // setFormData({ name: "", email: "", mobile: "", message: "" });
      })
      .catch((error) => {
        console.error("Error:", error);
        setButtonText("Submit Now");
      });
  };

  return (
    <div className="container-fluid callback my-5 pt-5">
      <div className="container pt-5">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div
              className="bg-white border rounded p-4 p-sm-5 wow fadeInUp"
            >
              <div className="text-center mx-auto wow fadeInUp">
                <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                  Get In Touch
                </p>
                <h1 className="display-5 mb-5">Request A Call-Back</h1>
              </div>
              
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <label>Your Name</label>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <label>Your Email</label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                      <label>Your Mobile (10 digits)</label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a message here"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <label>Message</label>
                    </div>
                  </div>

                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Call_Back;
