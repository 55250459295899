import React from "react";
import "./Fact.scss";

function Fact() {

  return (
    <>
      {/* <!-- Facts Start --> */}
      <div className="container-fluid facts my-5 py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-sm-6 col-lg-3 text-center wow fadeIn"
            >
              <i className="fa fa-users fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white">
                6
              </h1>
              <span className="fs-5 text-white">Happy Clients</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
            <div
              className="col-sm-6 col-lg-3 text-center wow fadeIn"
            >
              <i className="fa fa-check fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white">
                1
              </h1>
              <span className="fs-5 text-white">Projects Completed</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
            <div
              className="col-sm-6 col-lg-3 text-center wow fadeIn"
            >
              <i className="fa fa-users-cog fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white">
                16
              </h1>
              <span className="fs-5 text-white">Dedicated Staff</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
            <div
              className="col-sm-6 col-lg-3 text-center wow fadeIn"
            >
              <i className="fa fa-award fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white">
                0
              </h1>
              <span className="fs-5 text-white">Awards Achieved</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}
    </>
  );
}

export default Fact;
