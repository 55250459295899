import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Algocallback.css"; // Import the CSS file

function Algocallback({ onClose }) {
  const cookies = new Cookies();
  const userId = cookies.get("id"); // Get user ID from cookies
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
  const [showPopup, setShowPopup] = useState(false); // Track popup visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Restrict input based on field type
    if (name === "name" && !/^[a-zA-Z]*$/.test(value)) return;
    if (name === "number" && !/^\d*$/.test(value)) return;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!/^[a-zA-Z]+$/.test(formData.name)) {
      alert("Name should only contain alphabets.");
      return;
    }

    if (!/^\d+$/.test(formData.number)) {
      alert("Number should only contain digits.");
      return;
    }

    try {
      const response = await axios.post(
        `https://www.upholic.in/callback.php?user_id=${userId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response); // Log the entire response object

      if (response.status === 200 && response.data.message === "Data inserted successfully.") {
        setIsSubmitted(true);
        setShowPopup(true);
        setFormData({   // Reset form data after successful submission
          name: "",
          number: "",
          email: "",
        });
        
        // Reset isSubmitted after 3 seconds (adjust as needed)
        setTimeout(() => {
          setIsSubmitted(false);
        }, 3000); // Reset after 3 seconds
      } else {
        console.error("Submission failed with status:", response.status);
        // Handle submission failure if needed
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle Axios request error
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  

  console.log("showPopup:", showPopup); // Log showPopup state for debugging

  return (
    <div className="algocallback-popup-overlay" onClick={handleOverlayClick}>
      <div className="algocallback-popup">
        <div className="algocallback-algoform-container">
          <form className="algocallback-form" onSubmit={handleSubmit}>
            <h4 className="algocallback-form-header">
              Book your Free Trial & Start Profit Booking
            </h4>
            <input
              type="text"
              id="algocallback-name"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type="tel"
              id="algocallback-number"
              name="number"
              placeholder="Number"
              value={formData.number}
              onChange={handleInputChange}
            />
            <input
              type="email"
              id="algocallback-email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <button type="submit">
              {isSubmitted ? "Submitted" : "Submit"}
            </button>
          </form>
        </div>
      </div>

      {/* Popup displayed conditionally */}
      {showPopup && (
        <div className="algocall-popup">
          <div className="algocall-popup-content">
            <h2>Thank You!</h2>
            <p>Thank you for showing interest in our service. We will get back to you soon.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Algocallback;