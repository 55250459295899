import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./Features.scss";
import { Link } from "react-router-dom";

function Features() {
  return (
    <>
      {/* <!-- Features Start --> */}
      <div className="container-xxl feature py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                Why Choosing Us!
              </p>
              <h1 className="display-5 mb-4">
                Few Reasons Why People Choosing Us!
              </h1>
              <p className="mb-4">
                Our team comprises seasoned professionals with a wealth of
                experience in the financial industry. Clients choose us for our
                deep knowledge and proven track record of delivering results.
              </p>
              <Link className="btn btn-primary py-3 px-5" to="/comingsoon">
                Explore More
              </Link>
            </div>
            <div className="col-lg-6 d-lg-flex d-none">
              <div className="row g-4 align-items-center">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn">
                      <div className="feature-box border rounded p-4">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="fa-3x text-primary mb-3"
                        />
                        <h4 className="mb-3">Fast Executions</h4>
                        <p className="mb-3">
                          Speed and efficiency are paramount in the world of
                          finance, and we pride ourselves on our ability to
                          execute quickly and accurately.
                        </p>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn">
                      <div className="feature-box border rounded p-4">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="fa-3x text-primary mb-3"
                        />
                        <h4 className="mb-3">Guide & Support</h4>
                        <p className="mb-3">
                          Our clients choose us because we're more than just a
                          financial service provider — we're trusted guides and
                          steadfast supporters on their journey to financial
                          success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 wow fadeIn">
                  <div className="feature-box border rounded p-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="fa-3x text-primary mb-3"
                    />
                    <h4 className="mb-3">Financial Secure</h4>
                    <p className="mb-3">
                      Ensuring financial security is our top priority. Clients
                      choose us because we provide robust financial solutions
                      designed to safeguard their assets and protect their
                      future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-none">
              <div className="swiper-container">
                <div className="feature-box border rounded p-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="fa-3x text-primary mb-3"
                  />
                  <h4 className="mb-3">Fast Executions</h4>
                  <p className="mb-3">
                    Speed and efficiency are paramount in the world of finance,
                    and we pride ourselves on our ability to execute quickly and
                    accurately.
                  </p>
                </div>
                <div className="feature-box border rounded p-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="fa-3x text-primary mb-3"
                  />
                  <h4 className="mb-3">Guide & Support</h4>
                  <p className="mb-3">
                    Our clients choose us because we're more than just a
                    financial service provider — we're trusted guides and
                    steadfast supporters on their journey to financial success.
                  </p>
                </div>
                <div className="feature-box border rounded p-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="fa-3x text-primary mb-3"
                  />
                  <h4 className="mb-3">Financial Secure</h4>
                  <p className="mb-3">
                    Ensuring financial security is our top priority. Clients
                    choose us because we provide robust financial solutions
                    designed to safeguard their assets and protect their future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Features End --> */}
    </>
  );
}

export default Features;
