import React, { useState, useEffect } from "react";
import "./Tradingpackage.css";

function Tradingpackage() {
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default to monthly billing

  const pricingPlans = {
    monthly: { price: 4999, originalPrice: 4999, discount: null },
    halfYearly: { price: 19999, originalPrice: 29998, discount: "33% off" },
    yearly: { price: 34999, originalPrice: 59998, discount: "42% off" },
  };

  const plans = [
    {
      name: "Equity Services",
      explanation: "Small teams with up to 10 users.",
      features: [
        "24/7 customer support.",
        "Get 1-2 Calls on intraday basis through dedicated expert.",
        "Complete Resistance & Support on daily basis.",
        "Proper follow up through SMS.",
        "All important news and economy updates.",
      ],
    },
    {
      name: "Options Services",
      explanation: "Ideal for individual creators.",
      features: [
        "24/7 customer support.",
        "Get 1-2 Calls on intraday basis through dedicated expert.",
        "Complete Resistance & Support on daily basis.",
        "Proper follow up through SMS.",
        "Access links with secure password protection.",
      ],
    },
    {
      name: "Future Services",
      explanation: "Small teams with up to 10 users.",
      features: [
        "24/7 customer support.",
        "All important news and economy updates.",
        "Proper follow up through SMS.",
        "Complete Resistance & Support on daily basis.",
        "Get 1-2 Calls on intraday basis through dedicated expert.",
      ],
    },
    {
      name: "Commodity Services",
      explanation: "Killer for individual creators.",
      features: [
        "24/7 customer support.",
        "All important news and economy updates.",
        "Proper follow up through SMS.",
        "Complete Resistance & Support on daily basis.",
        "High Reward Ratio with Low Risk.",
      ],
      class: "center-card", // Add a class for centering
    },
    {
      name: "ATP Services",
      explanation: "Bigger for individual creators.",
      features: [
        "24/7 customer support.",
        "All important news and economy updates.",
        "Proper follow up through SMS.",
        "Complete Resistance & Support on daily basis.",
        "High Reward Ratio with Low Risk.",
      ],
      class: "center-card", // Add a class for centering
    },
  ];

  const handleSwitchChange = (cycle) => {
    setBillingCycle(cycle);
  };

  const selectedPricingPlan = pricingPlans[billingCycle] || {
    price: 0,
    originalPrice: 0,
  };

    const scrollToHeaderHeroSection = () => {
    const HeaderHeroSection = document.getElementById("trading-header");
    if (HeaderHeroSection) {
      HeaderHeroSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <h1 className="Price-heading" id="service-package">Advisory Package</h1>
      <div className="Price-switch">
        <button
          onClick={() => handleSwitchChange("monthly")}
          className={billingCycle === "monthly" ? "active" : ""}
        >
          Monthly
        </button>
        <button
          onClick={() => handleSwitchChange("halfYearly")}
          className={billingCycle === "halfYearly" ? "active" : ""}
        >
          Half-Yearly
        </button>
        <button
          onClick={() => handleSwitchChange("yearly")}
          className={billingCycle === "yearly" ? "active" : ""}
        >
          Yearly
        </button>
      </div>
      <div className="Price-container">
        {plans.map((plan, index) => (
          <div
            className={`Price-card ${plan.class}`}
            key={index}
          >
            <span className="Price-planName">{plan.name}</span>
            <h1 className="Price-price">
              {selectedPricingPlan.originalPrice >
                selectedPricingPlan.price && (
                <span className="Price-original">
                  ₹{selectedPricingPlan.originalPrice.toLocaleString()}
                </span>
              )}
              ₹{selectedPricingPlan.price.toLocaleString()}
              <span className="Price-small"> / {billingCycle}</span>
              {selectedPricingPlan.discount && (
                <span className="Price-discount">
                  {selectedPricingPlan.discount}
                </span>
              )}
            </h1>
            <span className="Price-explanation">{plan.explanation}</span>
            <ul className="Price-ul">
              {plan.features.map((feature, index) => (
                <li key={index} className="Price-li">
                  {feature}
                </li>
              ))}
            </ul>
            <div className="buttons-package"> 
            <button className="Price-button buybutton">
              Buy
            </button>
            <button className="Price-button Price-button--demo" onClick={scrollToHeaderHeroSection}>
                Free Trial
              </button>
              </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Tradingpackage;
