import React, { useState, useEffect } from "react";
import "./Algohero.scss";
import Algocallback from "../algo_callback/Algocallback"; // Import Algocallback component

function Algohero() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <section className="Algo-hero" id="Algo-hero">
      <div className="Algo-hero-container">
        <div className="Algo-hero-content">
          <h1 className="Algo-hero-header">
            Algo Trading Execution Platform
          </h1>
          <h2 className="Algo-hero-subheader" >
            — Streamline Your Trades Effortlessly
          </h2>
          <div className="try-business">
            <button className="try-business-button" onClick={openPopup}>
              Get a Call Back
            </button>
          </div>
        </div>
      </div>
      {isPopupOpen && <Algocallback onClose={closePopup} />}
    </section>
  );
}

export default Algohero;
