import React, { useState } from "react";
import "./About_Us.scss";
import investment from "../../assets/images/investment_manag.jpg";
import wealth_management from "../../assets/images/wealth_mang.jpg";
import risk_management from "../../assets/images/risk_manage.jpg";
import financhial from "../../assets/images/fine.jpg";
import retirement from "../../assets/images/retirement.jpg";
import Insurance from "../../assets/images/insurence.jpg";
import Navbar from "../../pages/navbar/Navbar";

function About_Us() {
  return (
    <>
      <Navbar />
      <main className="wrapper">
        <section className="hero">
          <h1>About Us ?</h1>
          <article>
            <p>
              At Upholic, we are dedicated to providing top-tier financial
              services tailored to meet the diverse needs of our clients. Our
              comprehensive range of services is designed to help individuals,
              businesses, and institutions achieve their financial goals with
              confidence and clarity.
            </p>
          </article>
        </section>

        <section className="about-section">
          <div className="container">
            <div className="row clearfix">
              <div className="content-column col-md-6 col-sm-12 col-xs-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <h2 className="about_h2">
                      Financial Planning and Consulting
                    </h2>
                  </div>
                  <div className="about_text">
                    We offer expert financial planning and consulting services
                    to help you navigate your financial future. Our team of
                    experienced financial advisors works closely with you to
                    develop personalized strategies that align with your
                    objectives, whether you're planning for retirement, saving
                    for education, or seeking investment opportunities.
                  </div>
                </div>
              </div>

              <div className="image-column col-md-6 col-sm-12 col-xs-12">
                <div
                  className="inner-column"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image">
                    <img src={financhial} alt="" className="aboutt_img" />
                    <div className="overlay-box">
                      <div className="year-box">
                        <span className="number">17</span>Years <br />{" "}
                        Experience <br /> Working
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="breweries" id="breweries">
          <ul>
            <li>
              <figure>
                <img
                  src={investment}
                  alt="Several hands holding beer glasses"
                />
                <figcaption>
                  <h3>Investment Management</h3>
                </figcaption>
              </figure>
              <p>
                Our investment management services are designed to help you grow
                and protect your wealth. We employ a disciplined approach to
                portfolio management, leveraging our deep market insights and
                research to create diversified investment portfolios. Our goal
                is to maximize returns while managing risk, ensuring your
                investments align with your financial goals and risk tolerance.
              </p>
            </li>
            <li>
              <figure>
                <img
                  src={wealth_management}
                  alt="Several friends doing a toast"
                />
                <figcaption>
                  <h3>Wealth Management</h3>
                </figcaption>
              </figure>
              <p>
                For high-net-worth individuals and families, our wealth
                management services provide comprehensive solutions to manage
                and grow your assets. From estate planning and tax optimization
                to philanthropy and legacy planning, we offer a holistic
                approach to wealth management that addresses all aspects of your
                financial life.
              </p>
            </li>
            <li>
              <figure>
                <img
                  src={risk_management}
                  alt="Three different glasses of beer"
                />
                <figcaption>
                  <h3>Risk Management</h3>
                </figcaption>
              </figure>
              <p>
                Managing financial risk is crucial in today's volatile market
                environment. Our risk management services are designed to
                identify, assess, and mitigate financial risks that could impact
                your financial health. We use advanced analytics and risk
                assessment tools to provide you with a clear understanding of
                potential risks and the strategies to manage them effectively.
              </p>
            </li>
          </ul>
        </section>
      </main>

      <section className="breweriess" id="breweriess">
        <ul>
          <li>
            <figure>
              <img src={retirement} alt="Several hands holding beer glasses" />
              <figcaption>
                <h3>Retirement Planning</h3>
              </figcaption>
            </figure>
            <p>
              Planning for retirement is a critical component of financial
              well-being. Our retirement planning services help you create a
              roadmap for a secure and comfortable retirement. We analyze your
              current financial situation, project future needs, and develop a
              plan that ensures you can maintain your desired lifestyle in
              retirement.
            </p>
          </li>
          <li>
            <figure>
              <img src={Insurance} alt="Several friends doing a toast" />
              <figcaption>
                <h3>Insurance Service</h3>
              </figcaption>
            </figure>
            <p>
              Protecting your assets and loved ones is a key aspect of financial
              planning. We offer a range of insurance services, including life,
              health, and property insurance, to provide you with the coverage
              you need. Our insurance experts help you select the right policies
              to safeguard your financial future.
            </p>
          </li>
        </ul>
      </section>

      <section className="py-4">
        <div className="container">
          <div className="row my-5">
            <div className="col-md-6 mx-auto text-center">
              <h2>FAQ</h2>
              <p></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="accordion" id="accordionRental">
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button border-bottom font-weight-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Expertise and Experience?
                      <i className="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"></i>
                      <i className="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"></i>
                    </button>
                  </h5>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      Our team comprises seasoned financial professionals with
                      extensive industry experience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button border-bottom font-weight-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Personalized Service ?
                      <i className="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"></i>
                      <i className="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"></i>
                    </button>
                  </h5>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      We take the time to understand your unique financial
                      situation and tailor our services to meet your specific
                      needs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button border-bottom font-weight-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Client-Centric Approach ?
                      <i className="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"></i>
                      <i className="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"></i>
                    </button>
                  </h5>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      Your financial success is our priority. We are committed
                      to providing you with the highest level of service and
                      support.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button border-bottom font-weight-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Integrity and Transparency ?
                      <i className="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"></i>
                      <i className="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"></i>
                    </button>
                  </h5>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      We operate with the utmost integrity and transparency,
                      ensuring you can trust us with your financial future.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About_Us;
