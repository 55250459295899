import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Sign_up from "./pages/sign-up/Sign_up";
import Dashboard_Main from "./dashboard_/dashborad_main/Dashboard_Main";
import Popup from "./pages/popup/Popup";
import Coming_Soon from "./dashboard_/coming_soon/Coming_Soon";
import Dashboard_Popup from "./dashboard_/dashboard_popup/Dashboard_Popup";
import Pie_Chart from "./dashboard_/pie-chart/Pie_Chart";
import About_Us from "./about/about-us/About_Us";
import Myprofile from "./dashboard_/myprofile/Myprofile";
import EditProfile from "./dashboard_/myeditprofile/EditProfile";
import TradingSoftware from "./dashboard_/trading_software/TradingSoftware";
import Tradingpackage from "./dashboard_/trading_service_package/Tradingpackage";
import Algotrading from "./dashboard_/algo_trading/Algotrading";
import Algocallback from "./dashboard_/algo_callback/Algocallback";
import Algosupport from "./dashboard_/algo_support/Algosupport";
import Life_Insurance_Home from "./dashboard_/life_insurance_home/Life_Insurance_Home";
import Life_Term_Insurance from "./dashboard_/life_term_insurance/Life_Term_Insurance";
import Family_Insurance from "./dashboard_/family_insurance/Family_Insurance";
import Retirement_Plan from "./dashboard_/retirement_plan/Retirement_Plan";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/about" element={<About_Us />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Sign_up />}></Route>

          <Route path="/dashboard" element={<Dashboard_Main />} />
          <Route path="/popup" element={<Popup />} />
          <Route path="/comingsoon" element={<Coming_Soon />} />
          <Route path="/dashboard_popup" element={<Dashboard_Popup />} />
          <Route path="/piechart" element={<Pie_Chart />} />
          <Route path="/myprofile" element={<Myprofile />}></Route>
          <Route path="/editprofile" element={<EditProfile />}></Route>
          <Route path="/tradingsoftware" element={<TradingSoftware/>}></Route>
          <Route path="/tradingpackage" element={<Tradingpackage/>}></Route>
          <Route path="/algotrading" element={<Algotrading/>}></Route>
          <Route path="/algocallback" element={<Algocallback/>}></Route>
          <Route path="/supportsoftware" element={<Algosupport/>}></Route>
          <Route path="/lifeinsurance" element={<Life_Insurance_Home/>}></Route>
          <Route path="/life-term" element={<Life_Term_Insurance/>}></Route>
          <Route path="/family-insurance" element={<Family_Insurance/>}></Route>
          <Route path="/retirement-plan" element={<Retirement_Plan/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
