import React, { useEffect } from "react";
import "./Algofeature.css"; // Updated CSS file import path
import bridge from "../../assets/images/bridge.png";
import arrowapi from "../../assets/images/arrowapi.png";
import orderlog from "../../assets/images/orderlog.png";
import watchlist from "../../assets/images/watchlist.png";
import orderbook from "../../assets/images/oderbook.png"; // Typo corrected: orderbook.png
import trackbook from "../../assets/images/tradebook.png";
import positionbook from "../../assets/images/positionbook.png";
import holding from "../../assets/images/holding.png";

function Algofeature() {

  return (
    <>
      <h1 className="feature-algo-head-service" id="Algo-feature">Core Features</h1>
      <div className="feature-algo-div-service">
        <div className="feature-algo-cards-container-service">
          {/* Feature Card 1 - ARROW-Bridge */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={bridge} alt="ARROW-Bridge Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">ARROW-Bridge</h5>
                <p className="feature-algo-card-text-service">ARROW-Bridge connects your trading platform to your broker.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 2 - ARROW-API */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={arrowapi} alt="ARROW-API Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">ARROW-API</h5>
                <p className="feature-algo-card-text-service">ARROW-API facilitates automated trading solutions on Upholic.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 3 - Order Log */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={orderlog} alt="Order Log Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">Order Log</h5>
                <p className="feature-algo-card-text-service">Order Log provides timestamp details of executed orders.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 4 - Watchlist */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={watchlist} alt="Watchlist Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">Watchlist</h5>
                <p className="feature-algo-card-text-service">Watch real-time stocks list to analyze price movements.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 5 - Holdings */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={holding} alt="Holdings Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">Holdings</h5>
                <p className="feature-algo-card-text-service">Get all the holdings held by a Trader with real-time P&L.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 6 - Orderbook */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={orderbook} alt="Orderbook Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">Orderbook</h5>
                <p className="feature-algo-card-text-service">Provides you complete view of orders placed in your Trading Account.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 7 - Tradebook */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={trackbook} alt="Tradebook Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">Tradebook</h5>
                <p className="feature-algo-card-text-service">Provides you complete view of trades placed in your Trading Account.</p>
              </div>
            </div>
          </div>

          {/* Feature Card 8 - Positionbook */}
          <div className="feature-algo-card-service">
            <div className="feature-algo-card-body-service">
              <img src={positionbook} alt="Positionbook Icon" className="feature-algo-arrow-img" />
              <div className="feature-algo-text-service">
                <h5 className="feature-algo-card-title-service">Positionbook</h5>
                <p className="feature-algo-card-text-service">Get all the positions held by a Trader with real-time MTM.</p>
              </div>
            </div>
          </div>

          {/* Add more feature cards as needed */}
        </div>
      </div>
    </>
  );
}

export default Algofeature;