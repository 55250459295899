import React from "react";
import "./Life_Insurance_Product.scss";
import { Link } from "react-router-dom";

function LifeInsuranceProduct() {
  return (
    <>
      <div className="Insurance-dashboard">
        <div className="exercise-table">
          <h2 className="Insurance-heading">Top Insurance</h2>
          <table className="insurance-table">
            <tbody className="insurance-tbody">
              <tr className="insurance-row" id="europe1">
                <Link to="/life-term">
                  <td className="package-europe">Life Term Insurance</td>
                </Link>
              </tr>
              <tr className="insurance-row" id="europe2">
                <Link to="/family-insurance">
                  <td className="package-europe">Family Insurance</td>
                </Link>
              </tr>
              <tr className="insurance-row" id="europe3">
                <Link to="/comingsoon">
                  <td className="package-europe">Retirement Plan</td>
                </Link>
              </tr>
              <tr className="insurance-row" id="europe4">
                <Link to="/comingsoon">
                  <td className="package-europe">Car Insurance</td>
                </Link>
              </tr>
              <tr className="insurance-row" id="europe5">
                <Link to="/comingsoon">
                  <td className="package-europe">Bike Insurance</td>
                </Link>
              </tr>
              <tr className="insurance-row" id="europe6">
                <Link to="/comingsoon">
                  <td className="package-europe">Home Insurance</td>
                </Link>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default LifeInsuranceProduct;