import React from "react";
import Slider from "react-slick";
import './Life_Insurance_Crousal.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faHeartbeat, faHome, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function Life_Insurance_Crousal() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="blk-prd featurebox">
      <div className="life-insurance-container featurebanner">
        <Slider {...settings}>
          <div>
            <a target="_blank" rel="noopener noreferrer">
              <article className="investment-plans prd-bg investment-bg">
                <div className="block-data">
                  <div className="icon-bg">
                    <FontAwesomeIcon icon={faChartLine} className="life-insurance-icon investment-icon" />
                  </div>
                  <div className="left-container">
                    <p className="head">Investment Plans</p>
                    <div>
                      <p className="copy">
                        Invest &#8377;10K and Get <br />
                        <span className="large-text">&#8377;1 Crore</span> return*
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </a>
          </div>
          <div>
            <article className="health-insurance prd-bg health-bg">
              <div className="block-data">
                <div className="icon-bg">
                  <FontAwesomeIcon icon={faHeartbeat} className="life-insurance-icon health-icon" />
                </div>
                <div className="left-container">
                  <p className="head">Health Insurance</p>
                  <div>
                    <p className="copy invfundData">
                      <span>
                        1 Crore
                        <br /> Health coverage
                      </span>
                      <br />
                      now cheaper than 5 lacs
                    </p>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div>
            <article className="home-insurance prd-bg home-bg">
              <div className="block-data">
                <div className="icon-bg">
                  <FontAwesomeIcon icon={faHome} className="life-insurance-icon home-icon" />
                </div>
                <div className="left-container">
                  <p className="head">Home Insurance</p>
                  <div>
                    <p className="copy">
                      <span>&#8377;50 Lakh Cover </span>for Your <br />
                      Home Insurance starting <br />
                      at Just <span>&#8377;80/month*</span>
                    </p>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div>
            <article className="pbaskci prd-bg askpb-bg">
              <div className="block-data">
                <div className="icon-bg">
                  <FontAwesomeIcon icon={faQuestionCircle} className="life-insurance-icon askpb-icon" />
                </div>
                <div className="left-container">
                  <p className="head">AskPB</p>
                  <div>
                    <p className="copy">
                      Got a{" "}
                      <span>
                        question <br /> about insurance?
                      </span>
                      <br /> Write to us
                    </p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Life_Insurance_Crousal;