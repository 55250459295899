import React from 'react';
import './Algohor.css';
import algotrade from '../../assets/images/algtrade.jpg';

function Algohor() {
  return (
    <>
      <div className="algohrowrapper">
        <h1 className='algohroh1'>Trading Algorithms</h1>
        <div className="algohrocard algohrocard--reverse">
          <div className="algohrocard__pic">
            <img className="algohroimg" src={algotrade} alt="Algo Trading" />
          </div>
          <div className="algohrocard__content">
            <h2 className='algohroh2'>Algo Mastery</h2>
            <p className='algohrop'>
              Algorithmic Trading, or Algo Trading, automates financial securities trading using computer algorithms.
              It relies on quantitative analysis, speed, and efficiency to execute predefined strategies.
              Common approaches include market making, arbitrage, and high-frequency trading.
              Algo trading requires quantitative skills, programming expertise, and adherence to regulatory standards.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Algohor;