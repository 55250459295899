import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Algoheader.css";
import axios from "axios";
import Logo from "../../assets/images/Upholic_Logo.png";
import ProfileMenu from "../profile/ProfileMenu";
import PMSImage from "../../assets/images/PMS2.jpg";
import LIImage from "../../assets/images/lifeinsurence.jpg";
import DepositeImage from "../../assets/images/depositename.jpg";
import SoftwareImage from "../../assets/images/tradingsoftware.jpg";
import Cookies from "universal-cookie"; // Import Cookies from 'universal-cookie'
import NotificationMenu from "../notification/Notification"; // Import your NotificationMenu component

function Algoheader() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const cookies = new Cookies(); // Create an instance of Cookies
  const username = cookies.get("username");
  const userId = cookies.get("id"); // Get user ID from cookies

  const toggleNotificationMenu = () => {
    setIsNotificationVisible(!isNotificationVisible);
  };


  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!userId) {
          console.error("User ID not found.");
          return;
        }

        const imageUrl = `https://www.upholic.in/Upholic_Api/uploads/${encodeURIComponent(
          userId
        )}_profile.png`;
        console.log("Image URL:", imageUrl); // Debugging statement

        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/imageget.php",
          {
            params: { url: imageUrl },
            responseType: "blob",
          }
        );

        console.log("Axios Response:", response);

        if (response.status === 200) {
          const imageUrlBlob = URL.createObjectURL(response.data);
          setImageSrc(imageUrlBlob);
          console.log("Image Src:", imageUrlBlob); // Debugging statement
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching the image:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };

    fetchImage();
  }, [userId]);

  // Function to toggle popup
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Effect to handle clicks outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const scrollToServicePackage = () => {
    const servicePackageSection = document.getElementById("algo-package");
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAlgoFeature = () => {
    const servicePackageSection = document.getElementById("Algo-feature");
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAlgoService = () => {
    const servicePackageSection = document.getElementById("Algo-Service");
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to toggle offcanvas menu
  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg fixed-top ${
          isOffcanvasOpen ? "navbar-behind-popup" : ""
        }`}
      >
        <div className="container-fluid">
          <a className="navbar-brand me-auto">
            <img src={Logo} alt="Upholic Logo" className="navbar-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleOffcanvas}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`offcanvas offcanvas-end ${
              isOffcanvasOpen ? "show" : ""
            }`}
            tabIndex="-1"
          >
            <div className="offcanvas-header">
              <a className="navbar-brand me-auto" href="/">
                <img src={Logo} alt="Upholic Logo" className="navbar-logo" />
              </a>
              <button
                type="button"
                className="btn-close"
                onClick={toggleOffcanvas}
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                     <a className="nav-link ex-lg-2" onClick={() => { toggleOffcanvas(); scrollToAlgoService(); }}>
                    Algo-Services
                  </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link ex-lg-2" onClick={() => { toggleOffcanvas(); scrollToAlgoFeature(); }}>
                    Feature
                  </a>
                </li>
                <li className="nav-item">
                     <a className="nav-link ex-lg-2" onClick={() => { toggleOffcanvas(); scrollToServicePackage(); }}>
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link ex-lg-2"
                    to="/supportsoftware"
                    onClick={scrollToTop}
                  >
                    Supported-software
                  </Link>
                </li>
                <li className="nav-item dropdown" ref={dropdownRef}>
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    onClick={togglePopup}
                  >
                    Services
                  </a>
                  <ul
                    className={`dropdown-menu ${isPopupOpen ? "show" : ""}`}
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {/* Dropdown items with images */}
                    <li>
                      <Link className="dropdown-item" to="/dashboard">
                        <img
                          src={PMSImage} // Replace with your image source
                          alt="PMS"
                          className="dropdown-item-image"
                        />
                        PMS
                      </Link>
                    </li>
                    <li>
                      <a className="dropdown-item">
                        <img
                          src={LIImage} // Replace with your image source
                          alt="LI"
                          className="dropdown-item-image"
                        />
                        LI
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item">
                        <img
                          src={DepositeImage} // Replace with your image source
                          alt="Deposite"
                          className="dropdown-item-image"
                        />
                        Deposite
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/tradingsoftware">
                        <img
                          src={SoftwareImage} // Replace with your image source
                          alt="Deposite"
                          className="dropdown-item-image"
                        />
                        Trading Software
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="user-nav ms-auto d-flex align-items-center">
            <button className="notification butnotif" onClick={toggleNotificationMenu}>
              <svg
                className="notification__icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0201 2.91003C8.71009 2.91003 6.02009 5.60003 6.02009 8.91003V11.8C6.02009 12.41 5.76009 13.34 5.45009 13.86L4.30009 15.77C3.59009 16.95 4.08009 18.26 5.38009 18.7C9.69009 20.14 14.3401 20.14 18.6501 18.7C19.8601 18.3 20.3901 16.87 19.7301 15.77L18.5801 13.86C18.2801 13.34 18.0201 12.41 18.0201 11.8V8.91003C18.0201 5.61003 15.3201 2.91003 12.0201 2.91003Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
              </svg>
            </button>
            <div
              className="user-info d-flex align-items-center"
              onClick={toggleMenu}
            >
              <div className="circle cricle_body">
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <img src={imageSrc} alt="profile" className="profile-image" />
                )}
              </div>
              <span className="user-name algoname">{username}</span>{" "}
              {/* Place username next to profile */}
            </div>
            <ProfileMenu
              isVisible={isVisible}
              onClose={() => setIsVisible(false)}
            />
            <NotificationMenu
                isVisible={isNotificationVisible}
                onClose={() => setIsNotificationVisible(false)}
              />
            <svg
              className="algosidepro"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5999 7.45837L11.1666 12.8917C10.5249 13.5334 9.4749 13.5334 8.83324 12.8917L3.3999 7.45837"
                stroke="#596780"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </nav>
      <section className="hero-section"></section>
    </>
  );
}

export default Algoheader;
