import React from 'react';
import Algocards from '../algo_cards/Algocards';
import Algosignal from '../algo_signal/Algosignal';
import Algohor from '../algo_hor_card/Algohor';
import Algohero from '../algo_hero/Algohero';
import Algoheader from '../algo_header/Algoheader';
import Algopricing from '../algo_pricing/Algopricing';
import Algofooter from '../algo_footer/Algofooter';
import Algofeature from '../algo_feature/Algofeature';

function Algotrading() {
  return (
    <>
    <Algoheader/>
    <Algohero/>
    <Algopricing/>
    <Algocards/>
    <Algosignal/>
    <Algofeature/>
    <Algohor/>
    <Algofooter/>
    </>
  )
}

export default Algotrading