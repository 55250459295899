import React, { useEffect } from 'react';
import './Statscounter.css';

function Statscounter() {
    useEffect(() => {

        const counters = document.querySelectorAll('.count-number');
        const delay = 10;
        const time = 1000;

        counters.forEach(counter => {
            const updateCount = () => {
                const target = +counter.getAttribute('data-count');
                const count = +counter.innerText;

                const increment = target / (time / delay);

                if (count < target) {
                    counter.innerText = Math.ceil(count + increment) + '+';
                    setTimeout(updateCount, delay);
                } else {
                    counter.innerText = target + '+';
                }
            };

            updateCount();
        });
    }, []);

    return (
        <section id="statistic" className="statistic-section one-page-section">
            <div className="container">
                <div className="row text-center">
                    <div className="col-xs-12 col-md-3">
                        <div className="counter">
                            <h2 className="timer count-title count-number" data-count="11000">0</h2>
                            <div className="stats-line-black"></div>
                            <p className="stats-text">Trading Tip</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <div className="counter">
                            <h2 className="timer count-title count-number" data-count="15">0</h2>
                            <div className="stats-line-black"></div>
                            <p className="stats-text">Years of Experience</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <div className="counter">
                            <h2 className="timer count-title count-number" data-count="6">0</h2>
                            <div className="stats-line-black"></div>
                            <p className="stats-text">Trade Specialist</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <div className="counter">
                            <h2 className="timer count-title count-number" data-count="30">0</h2>
                            <div className="stats-line-black"></div>
                            <p className="stats-text">Happy Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Statscounter;
