import React from 'react'
import Life_insurance_Hero from '../life_insurance_hero/Life_insurance_Hero'
import Life_Insurance_Product from '../life_insurance_product/Life_Insurance_Product'
import Life_insurance_Navbar from '../life_insurance_navbar/Life_insurance_Navbar'
import Life_Insurance_Content from '../life_insurance_content/Life_Insurance_Content'
import Life_Insurance_Crousal from '../life_insurance_crousal/Life_Insurance_Crousal'
import Life_Insurance_Footer from '../life_insurance_footer/Life_Insurance_Footer'

function Life_Insurance_Home() {
  return (
    <>
    <Life_insurance_Navbar/>
    <Life_insurance_Hero/>
    <Life_Insurance_Product/>
    <Life_Insurance_Crousal/>
    <Life_Insurance_Content/>
    <Life_Insurance_Footer/>
    </>
  )
}

export default Life_Insurance_Home