import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import "./Life_insurance_Hero.css";
import firstimg from "../../assets/images/insurancehero.jpg";

function LifeInsuranceHero() {
  const typedElement = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: [
        '<span style="color: red;">Welcome to Our Insurance Portal</span>', 
        '<span style="color: red;">Protect your future with the right insurance</span>'
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 1000,
      loop: true,
      contentType: 'html' // Ensure HTML content is parsed
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="insurance-body">
      {/* HERO SECTION */}
      <div className="container-fluid position-relative overflow-hidden mb-4 mb-sm-0">
        <div className="container mt-xl-5 mt-lg-5">
          <div className="row pt-xl-5 pt-lg-5 d-sm-flex flex-column-reverse flex-lg-row text-center text-md-start">
            <div className="col-xl-6 col-lg-7 pt-xl-5 mt-xl-5 mt-lg-5">
              <h3 className="h3 fw-semibold opacity-75">
                <span ref={typedElement}></span>
              </h3>
              <h1 className="h1 fw-bold">
                Get the best{" "}
                <span className="highlighted-text px-2 text-white rounded">
                  insurance plans
                </span>{" "}
                for your needs
              </h1>
              <p className="fs-6 fw-medium w-75 width-100-important opacity-75">
                Our insurance portal offers a variety of plans to suit your needs. Join now to secure your future and protect your loved ones.
              </p>
              <a
                href="#"
                className="insurance-btn insurance-btn-primary text-white fs-5"
              >
                Get started
              </a>
            </div>
            <div className="col-xl-6 col-lg-5 mt-lg-5 position-relative">
              <img src={firstimg} alt="Hero Section" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LifeInsuranceHero;
