import React from "react";
import "./Life_Term_Insurance.css";

function Life_Term_Insurance() {
  return (
    <div className="main-life-term-container">
      <h1>
        <span className="highlight-orange">₹1 Crore</span> life cover starting
        from <span className="highlight-red">₹473/month+</span>
      </h1>
      <div className="life_term_container">
        <label htmlFor="filter" className="switch" aria-label="Toggle Filter">
          <input type="checkbox" id="filter" />
          <span>Male</span>
          <span>Female</span>
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="name">Name :</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter your name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="dob">Date of Birth :</label>
        <input type="date" id="dob" name="dob" />
      </div>
      <div className="form-group">
        <label htmlFor="mobile">Mobile Number :</label>
        <input
          type="tel"
          id="mobile"
          name="mobile"
          placeholder="Enter your mobile number"
        />
      </div>
      <button className="view-plan-btn">View Plan</button>
      <div className="disclaimer">
        <p>
          Term Insurance: Only certified Upholic experts will assist you. By
          clicking, you agree to our{" "}
          <a href="/privacy-policy">Privacy Policy</a>,
          <a href="/terms-of-use"> Terms of Use</a>, &
          <a href="/disclaimers"> Disclaimers</a>.
        </p>
      </div>
    </div>
  );
}

export default Life_Term_Insurance;
