import React, { useEffect } from "react";
import "./Tradingfooter.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

function Tradingfooter() {

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };

  const scrollToServicePackage = () => {
    const servicePackageSection = document.getElementById("service-package");
    if (servicePackageSection) {
      servicePackageSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4 footerheading">Our Office</h4>
              <p className="mb-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3" />
                1401 Maple, Mahavir Kalpravriksha, Kasarwadavali
                Thane(W)-400615,Maharashtra, Bharat
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faPhoneAlt} className="me-3" />
                7738389334
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                info@upholic.com
              </p>
              <div className="d-flex pt-2 social-icons">
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4 footerheading">Services Package</h4>
              <Link className="btn btn-link"  onClick={scrollToServicePackage}>
                Equity Services
              </Link>
              <Link className="btn btn-link"  onClick={scrollToServicePackage}>
                Options Services
              </Link>
              <Link className="btn btn-link"  onClick={scrollToServicePackage}>
                Future Services
              </Link>
              <Link className="btn btn-link"  onClick={scrollToServicePackage}>
                Commodity Services
              </Link>
              <Link className="btn btn-link"  onClick={scrollToServicePackage}>
                ATP Services
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4 footerheading">Service</h4>
              <Link className="btn btn-link" to="/comingsoon">
                Stock Scanner
              </Link>
              <Link className="btn btn-link" to="/algotrading" onClick={scrollToTop}>
                Algo Trading
              </Link>
              <Link className="btn btn-link"  onClick={scrollToServicePackage}>
                Stock Advisory
              </Link>
              <Link className="btn btn-link" to="/comingsoon">
                Trading Signals
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4 footerheading">Stay Connected</h4>
              {/* <!-- <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> --> */}
              <div className="position-relative w-100">
                <input
                  className="form-control bg-white border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
}

export default Tradingfooter;
