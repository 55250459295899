import React, { useEffect } from "react";
import "./Carousel.scss"; // Import your existing CSS file
import Carousel_1 from "../../assets/images/carousel-1.jpg";
import Carousel_1_Phone from "../../assets/images/upholicimgmob.jpg"; // Add phone image import
import Carousel_2 from "../../assets/images/carousel-2.jpg";
import Carousel_2_Phone from "../../assets/images/upholicimgmob.jpg"; // Add phone image import
import { Link } from "react-router-dom";

function Carousel() {

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      const carousel = document.getElementById("header-carousel");
      const activeIndex = parseInt(
        carousel.querySelector(".carousel-item.active").dataset.index
      );
      const carouselItems = carousel.querySelectorAll(".carousel-item");
      const nextIndex = (activeIndex + 1) % carouselItems.length;
      carouselItems[activeIndex].classList.remove("active");
      carouselItems[nextIndex].classList.add("active");
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(carouselInterval);
  }, []);

  return (
      <>
      {/* Carousel Start */}
      <div className="container-fluid p-0 mb-5 wow fadeIn">
        <div id="header-carousel" className="carousel slide carousel-fade">
          <div className="carousel-inner">
            <div className="carousel-item active" data-index="0">
              <img className="w-100" src={Carousel_1} alt="First slide" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-8">
                      <p className="welcome-text">Welcome to UPHOLIC</p>
                      <h1 className="display-1 mb-4 mainh1text animated slideInDown">
                        Your Financial Status Is Our Goal
                      </h1>
                      <Link
                        to="/signup"
                        className="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Explore More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-index="1">
              <img className="w-100" src={Carousel_2} alt="Second slide" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-7">
                      <p className="welcome-text">Welcome to UPHOLIC</p>
                      <h1 className="display-1 mb-4 mainh1text animated slideInDown">
                        True Financial Support For You
                      </h1>
                      <Link
                        to="/signup"
                        className="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Explore More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}
    </>
  );
}

export default Carousel;
