import React, { useEffect } from "react";
import "./Algocards.css"; // Updated CSS file import path
import algoritrading from "../../assets/images/algoritrading.png";
import ordermanage from "../../assets/images/Order_Management.png";
import Terminal from "../../assets/images/Trading_Terminal.png";
import Software from "../../assets/images/Software.png";
import far from "../../assets/images/Fast_and_Reliable.png";
import Automation from "../../assets/images/Automation.png";
import Execution from "../../assets/images/ExecutionAlgos.png";
import Strategies from "../../assets/images/Strategies.png";

function Algocards() {

  return (
    <>
      <h1 className="algo-head-service" id="Algo-Service">Services</h1>
      <div className="algo-div-service">
        <div className="algo-cards-container-service">
          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={algoritrading} alt="Algorithmic Trading Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Algorithmic Trading</h5>
                <p className="algo-card-text-service">Upholic covers the full life cycle of algorithmic trading.</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={ordermanage} alt="Order Management Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Order Management</h5>
                <p className="algo-card-text-service">Upholic offers flexible order management for the Algo Orders.</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={Strategies} alt="Strategies Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Strategies</h5>
                <p className="algo-card-text-service">Upholic provides a wide range of useful features to create quantitative...</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={Execution} alt="Execution Algos Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Execution Algos</h5>
                <p className="algo-card-text-service">Using upholic you can execute any order types in the market.</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={Automation} alt="Automation Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Automation</h5>
                <p className="algo-card-text-service">Using upholic you can reduce human intervention.</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={far} alt="Fast and Reliable Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Fast and Reliable</h5>
                <p className="algo-card-text-service">Runs multiple low latency strategies that trade in bunch of symbols at a time.</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={Software} alt="Software Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Software</h5>
                <p className="algo-card-text-service">Supports multiple software platforms like Amibroker, Metatrader, Excel etc.</p>
              </div>
            </div>
          </div>

          <div className="algo-card-service">
            <div className="algo-card-body-service">
              <img src={Terminal} alt="Trading Terminal Icon" className="algo-arrow-img" />
              <div className="algo-text-service">
                <h5 className="algo-card-title-service">Trading Terminal</h5>
                <p className="algo-card-text-service">Get all your trades, orders, positions and holdings with multiple watchlist.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Algocards;