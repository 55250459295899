import React from "react";
import "./Life_Insurance_Content.css";
import bestimg from '../../assets/images/bestprice.png';
import Unbiased from '../../assets/images/Unbiased_Advice.png';
import reliable from '../../assets/images/Reliable.png';
import support from '../../assets/images/support.png';
import callimg from '../../assets/images/callimg.png'

function Life_Insurance_Content() {
  return (
    <div className="insurance-mid-row">
      <div className="content-container">
        <h2 className="insurance-h2">PB Advantage</h2>
        <p className="description-text">
          When you buy insurance from us, you get more than just financial
          safety. You also get: our promise of simplifying complex insurance
          terms and conditions, quick stress-free claims, instant quotes from
          top insurers, and being present for you in the toughest of times.
        </p>
        {/* <a href="#" className="cta-button">Know more</a> */}

        <div className="section_our_solution">
          <div className="our_solution_category">
            <div className="solution_cards_box">
              <div className="solution_card">
                <div className="hover_color_bubble"></div>
                <div className="so_top_icon">
                    <img src={bestimg} className="imgcontent"></img>
                </div>
                <div className="solu_title">
                  <h3>One of the best Prices</h3>
                </div>
                <div className="solu_description">
                  <p>Guaranteed</p>
                </div>
              </div>
              <div className="solution_card">
                <div className="hover_color_bubble"></div>
                <div className="so_top_icon">
                <img src={Unbiased} className="imgcontent"></img>
                </div>
                <div className="solu_title">
                  <h3>Unbiased Advice</h3>
                </div>
                <div className="solu_description">
                  <p>Keeping customers first
                  </p>
                </div>
              </div>
              <div className="solution_card">
                <div className="hover_color_bubble"></div>
                <div className="so_top_icon">
                <img src={reliable} className="imgcontent"></img>
                </div>
                <div className="solu_title">
                  <h3>100% Reliable</h3>
                </div>
                <div className="solu_description">
                  <p>
                  Regulated by IRDAI
                  </p>
                </div>
              </div>
              <div className="solution_card">
                <div className="hover_color_bubble"></div>
                <div className="so_top_icon"> 
                <img src={callimg} className="imgcontent"></img>
                </div>
                <div className="solu_title">
                  <h3>Claims Support</h3>
                </div>
                <div className="solu_description">
                  <p>
                  Made stress-free
                  </p>
                </div>
              </div>
              <div className="solution_card">
                <div className="hover_color_bubble"></div>
                <div className="so_top_icon">
                <img src={support} className="imgcontent"></img>
                </div>
                <div className="solu_title">
                  <h3>Happy to Help</h3>
                </div>
                <div className="solu_description">
                  <p>
                  Every day of the week
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Life_Insurance_Content;
