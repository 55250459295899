import React, { useState } from "react";
import "./Login.scss";
// import { FaXTwitter, FaGoogle, FaFacebook } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

function Login() {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `https://www.upholic.in/Upholic_Api/login.php?username=${
          formData.username
        }&password=${formData.password}&user_id=${cookies.get("id")}`
      );
      if (response.data.success) {
        console.log("Login successful front");
        navigate("/dashboard");
        // Set username and id in cookies for future use
        cookies.set("username", formData.username, { path: "/" });
        const userId = response.data.user_id;
        cookies.set("id", userId, { path: "/" });
        console.log("User ID:", userId);
      } else {
        console.error("Login failed: " + response.data.message);
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="login-body">
        <Link to="/" className="close-link">
          <ImCross className="close-icon" />
        </Link>
        <div className="login-container">
          <div className="login-content">
            <div className="login-content_header">
              <h2>Welcome Back</h2>
            </div>
            <div>
              <form className="login-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  required
                  value={formData.username}
                  onChange={handleChange}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                />
                {error && <p className="error-message">{error}</p>}
                <input type="submit" value="Login" />
              </form>
              {/* <div className="login-networks">
                <span>or Login with</span>
                <ul className="login-icons">
                  <li>
                    <FaGoogle color="#000" />
                  </li>
                  <li>
                    <FaXTwitter color="#000" />
                  </li>
                  <li>
                    <FaFacebook color="#000" />
                  </li>
                </ul>
              </div> */}
              <p>
                By creating an account you agree to Upholic{" "}
                <strong>Terms of Services</strong> and{" "}
                <strong>Privacy Policy.</strong>
              </p>
            </div>
          </div>
          <div className="login-footer"></div>
        </div>
      </div>
    </>
  );
}

export default Login;