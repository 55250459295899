import React, { useEffect, useState } from "react";
import "./Our_Services.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import service_image_1 from "../../assets/images/service-1.jpg";
import service_image_2 from "../../assets/images/service-2.jpg";
import service_image_3 from "../../assets/images/service-3.jpg";
import service_image_4 from "../../assets/images/service-4.jpg";
import { Link } from "react-router-dom";

function Our_Services() {

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      {/* <!-- Service Start --> */}
      <div className="container-xxl service py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Our Services
            </p>
            <h1 className="display-5 mb-5">
              Awesome Financial Services For Wealth Building
            </h1>
          </div>
          <div className="row g-4 wow fadeInUp">
            <div className="col-lg-4">
              <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                <button
                  className={`nav-link nav-linkk w-100 d-flex align-items-center text-start border p-4 mb-4 ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-1"
                  type="button"
                  onClick={() => handleTabClick(1)}
                >
                  <h5 className="m-0">
                    <FontAwesomeIcon
                      icon={faBars}
                      className={`text-primary me-3 ${
                        activeTab === 1 ? "active" : ""
                      }`}
                    />
                    Financial Planning
                  </h5>
                </button>
                <button
                  className={`nav-link nav-linkk w-100 d-flex align-items-center text-start border p-4 mb-4 ${
                    activeTab === 2 ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-2"
                  type="button"
                  onClick={() => handleTabClick(2)}
                >
                  <h5 className="m-0">
                    <FontAwesomeIcon
                      icon={faBars}
                      className={`text-primary me-3 ${
                        activeTab === 2 ? "active" : ""
                      }`}
                    />
                    PMS Services
                  </h5>
                </button>
                <button
                  className={`nav-link nav-linkk w-100 d-flex align-items-center text-start border p-4 mb-4 ${
                    activeTab === 3 ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-3"
                  type="button"
                  onClick={() => handleTabClick(3)}
                >
                  <h5 className="m-0">
                    <FontAwesomeIcon
                      icon={faBars}
                      className={`text-primary me-3 ${
                        activeTab === 3 ? "active" : ""
                      }`}
                    />
                    Life Insurance
                  </h5>
                </button>
                <button
                  className={`nav-link nav-linkk w-100 d-flex align-items-center text-start border p-4 mb-0 ${
                    activeTab === 4 ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-4"
                  type="button"
                  onClick={() => handleTabClick(4)}
                >
                  <h5 className="m-0">
                    <FontAwesomeIcon
                      icon={faBars}
                      className={`text-primary me-3 ${
                        activeTab === 4 ? "active" : ""
                      }`}
                    />
                    Loans
                  </h5>
                </button>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content w-100">
                <div className="tab-pane fade show active" id="tab-pane-1">
                  <div className="row g-4">
                    <div className="col-md-6 service-image">
                      <div
                        className="position-relative h-100"
                      >
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src={service_image_1}
                          alt="image"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="mb-4">
                        15 Years Of Experience In Financial Support
                      </h3>
                      <p className="mb-4">
                        With 15 years of experience in providing financial
                        support, we've honed our expertise and refined our
                        approach to meet the diverse needs of our clients. Our
                        journey began with a simple commitment to assist
                        individuals and businesses in navigating the complex
                        world of finance with confidence and clarity.
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>
                        Investment Planning
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>
                        Insurance Planning
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>Tax
                        Planning
                      </p>
                      <Link className="btn btn-primary py-3 px-5 mt-3" to="/comingsoon">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tab-pane-2">
                  <div className="row g-4">
                    <div className="col-md-6 service-image">
                      <div
                        className="position-relative h-100"
                      >
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src={service_image_2}
                          alt="image"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="mb-4">
                        Comprehensive & Customized Portfolio Management Services
                      </h3>
                      <p className="mb-4">
                        {" "}
                        PMS providers offer personalized portfolio management
                        services tailored to the specific investment objectives,
                        risk tolerance, and financial goals of individual
                        clients.
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>
                        Professional Expertise
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>Active
                        Management
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>Risk
                        Management
                      </p>
                      <Link className="btn btn-primary py-3 px-5 mt-3" to="/comingsoon">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tab-pane-3">
                  <div className="row g-4">
                    <div className="col-md-6 service-image">
                      <div
                        className="position-relative h-100"
                      >
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src={service_image_3}
                          alt="image"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                    <h3 className="mb-4">
                        {" "}
                        Expert Advice on Various Aspects of Life Insurance
                      </h3>
                      <p className="mb-4">
                        Life insurance consultants provide advice and
                        recommendations based on their clients' financial goals,
                        risk tolerance, and insurance preferences. They may help
                        clients develop life insurance strategies, select
                        appropriate insurance policies, and monitor policy
                        performance over time.
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>
                        Life Insurance Assessment
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>
                        Policy Management
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>
                        Retirement Planning
                      </p>
                      <Link className="btn btn-primary py-3 px-5 mt-3" to="/comingsoon">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tab-pane-4">
                  <div className="row g-4">
                    <div className="col-md-6 service-image">
                      <div
                        className="position-relative h-100"
                      >
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src={service_image_4}
                          alt="image"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="mb-4">
                        Loan Products Designed to Meet Your Financial Needs
                      </h3>
                      <p className="mb-4">
                        Our loans come with competitive rates, flexible terms,
                        and excellent customer service to ensure a smooth
                        borrowing experience. Whether you're looking for a
                        personal loan, home equity loan, or auto loan, we have
                        options to fit your needs.
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>Home
                        Loans
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>Personal
                        Loans
                      </p>
                      <p>
                        <i className="fa fa-check text-primary me-3"></i>Vehicle
                        Loans
                      </p>
                      <Link className="btn btn-primary py-3 px-5 mt-3" to="/comingsoon">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}
    </>
  );
}

export default Our_Services;
