import React, { useState } from "react";
import "./Family_Insurance.css";
import selfImg from "../../assets/images/selfimg.png";
import wifeImg from "../../assets/images/wifeimg.png";
import sonImg from "../../assets/images/sonimg.png";
import daughterImg from "../../assets/images/daughterimg.png";
import fatherImg from "../../assets/images/fatherimg.png";
import motherImg from "../../assets/images/motherimg.png";
import grandfatherImg from "../../assets/images/grandfatherimg.png";
import grandmotherImg from "../../assets/images/grandmotherimg.png";
import fatherInLawImg from "../../assets/images/fatherinlaw.png";
import motherInLawImg from "../../assets/images/motherimg.png";

const members = [
  { id: 1, label: "Self", imgSrc: selfImg },
  { id: 2, label: "Wife", imgSrc: wifeImg },
  { id: 3, label: "Son", imgSrc: sonImg, count: 0 },
  { id: 4, label: "Daughter", imgSrc: daughterImg, count: 0 },
  { id: 5, label: "Father", imgSrc: fatherImg },
  { id: 6, label: "Mother", imgSrc: motherImg },
  { id: 7, label: "Grandfather", imgSrc: grandfatherImg },
  { id: 8, label: "Grandmother", imgSrc: grandmotherImg },
  { id: 9, label: "Father-in-law", imgSrc: fatherInLawImg },
  { id: 10, label: "Mother-in-law", imgSrc: motherInLawImg },
];

function Family_Insurance() {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [counts, setCounts] = useState({ son: 0, daughter: 0 });
  const [showAgeForm, setShowAgeForm] = useState(false);
  const [showPersonalInfoForm, setShowPersonalInfoForm] = useState(false);
  const [age, setAge] = useState({});
  const [personalInfo, setPersonalInfo] = useState({ name: '', phone: '', city: '' });

  const toggleSelect = (id) => {
    const member = members.find((m) => m.id === id);
    const isSelected = selectedMembers.includes(id);

    setSelectedMembers((prevSelected) =>
      isSelected
        ? prevSelected.filter((memberId) => memberId !== id)
        : [...prevSelected, id]
    );

    if (
      !isSelected &&
      (member.label === "Son" || member.label === "Daughter")
    ) {
      const type = member.label.toLowerCase();
      setCounts((prevCounts) => ({
        ...prevCounts,
        [type]: 1,
      }));
    } else if (
      isSelected &&
      (member.label === "Son" || member.label === "Daughter")
    ) {
      const type = member.label.toLowerCase();
      setCounts((prevCounts) => ({
        ...prevCounts,
        [type]: 0,
      }));
    }
  };

  const handleContinue = () => {
    if (selectedMembers.length > 0) {
      setShowAgeForm(true);
    } else {
      alert("Please select at least one member before continuing.");
    }
  };

  const incrementCount = (type) => {
    const newCount = counts[type] + 1;
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: newCount,
    }));
    const memberId = members.find((m) => m.label.toLowerCase() === type).id;
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(memberId)
        ? prevSelected
        : [...prevSelected, memberId]
    );
  };

  const decrementCount = (type) => {
    const newCount = Math.max(counts[type] - 1, 0);
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: newCount,
    }));
    const memberId = members.find((m) => m.label.toLowerCase() === type).id;
    if (newCount === 0) {
      setSelectedMembers((prevSelected) =>
        prevSelected.filter((id) => id !== memberId)
      );
    }
  };

  const handleAgeChange = (e, memberLabel) => {
    setAge((prevAge) => ({
      ...prevAge,
      [memberLabel]: e.target.value,
    }));
  };

  const handleAgeContinue = () => {
    setShowAgeForm(false);
    setShowPersonalInfoForm(true);
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handlePersonalInfoSubmit = () => {
    alert(`Personal Info submitted: ${JSON.stringify(personalInfo)}`);
    // Handle form submission logic here
  };

  return (
    <div className="main-life-term-container">
      {showAgeForm ? (
        <div className="age-form-container">
          <h3>Enter Ages</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            {selectedMembers.map((id) => {
              const member = members.find((m) => m.id === id);
              return (
                member && (
                  <div key={id} className="age-input">
                    <label>{member.label}:</label>
                    <input
                      type="number"
                      min="0"
                      value={age[member.label] || ""}
                      onChange={(e) => handleAgeChange(e, member.label)}
                    />
                  </div>
                )
              );
            })}
            <button
              type="button"
              className="continue-btn"
              onClick={handleAgeContinue}
            >
              Continue
            </button>
          </form>
        </div>
      ) : showPersonalInfoForm ? (
        <div className="personal-info-form-container">
          <h3>Enter Personal Information</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="personal-info-input">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={personalInfo.name}
                onChange={handlePersonalInfoChange}
              />
            </div>
            <div className="personal-info-input">
              <label>Phone Number:</label>
              <input
                type="tel"
                name="phone"
                value={personalInfo.phone}
                onChange={handlePersonalInfoChange}
              />
            </div>
            <div className="personal-info-input">
              <label>City:</label>
              <input
                type="text"
                name="city"
                value={personalInfo.city}
                onChange={handlePersonalInfoChange}
              />
            </div>
            <button
              type="button"
              className="submit-btn"
              onClick={handlePersonalInfoSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="multi-card-select-container">
          <h2 className="discount-heading">
            Find top plans for you with up to <strong>25% discount</strong>
          </h2>
          <div className="life_term_container">
            <label
              htmlFor="filter"
              className="switch"
              aria-label="Toggle Filter"
            >
              <input type="checkbox" id="filter" />
              <span>Male</span>
              <span>Female</span>
            </label>
          </div>
          <h3 className="discount-heading">Select members you want to insure</h3>
          <div className="cards-container">
            {members.map((member) => (
              <div
                key={member.id}
                className={`card ${
                  selectedMembers.includes(member.id) ? "selected" : ""
                }`}
                onClick={() => toggleSelect(member.id)}
              >
                <img
                  src={member.imgSrc}
                  alt={member.label}
                  className="member-img"
                />
                <span>{member.label}</span>
                {(member.label === "Son" || member.label === "Daughter") && (
                  <div className="counter">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card selection
                        decrementCount(member.label.toLowerCase());
                      }}
                    >
                      -
                    </button>
                    <span>{counts[member.label.toLowerCase()]}</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card selection
                        incrementCount(member.label.toLowerCase());
                      }}
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button className="continue-btn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      )}
    </div>
  );
}

export default Family_Insurance;