import React from "react";
import "./Tradingcopyright.css";

function Tradingcopyright() {
  return (
    <>
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-3 mb-md-0">
              &copy;{" "}
              <a className="copyright-company" href="#">
                www.upholic.in
              </a>
              , All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tradingcopyright;
