import React, { useEffect } from 'react';
import './Tradingabout.css';
import stockmarket from '../../assets/images/Stock-market.jpg';

function Tradingabout() {

  return (
    <>
      <section className="section_all bg-light" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title_all text-center">
                <h2 className="font-weight-bold">About Us</h2>
                <p className="section_subtitle mx-auto text-muted">
                  Upholic Trading Software offers a powerful scanner to find top
                  trades, reliable trading signals for buy/sell alerts,
                  expert stock advisory, and automated trading with algo
                  trading. Trade smarter with Upholic!
                </p>
                <div className="">
                  <i className=""></i>
                </div>
              </div>
            </div>
          </div>

          <div className="row vertical_content_manage mt-5">
            <div className="col-lg-6">
              <div className="about_header_main mt-3">
                <div className="about_icon_box">
                  <p className="text_custom font-weight-bold">
                    Welcome To Upholic trading Softwares
                  </p>
                </div>
                <h4 className="about_heading text-capitalize font-weight-bold mt-4">
                  At Upholic, our advanced trading software empowers you to make
                  smarter, more profitable decisions.
                </h4>

                <h4 className="text-dark our-service-text">Why Choose Upholic?</h4>
                <ul className="text-muted mt-3">
                  <li className='aboutli'>
                    <strong className="text-dark">Innovative Technology:</strong> Leveraging AI and machine learning for superior trading tools.
                  </li>
                  <li className='aboutli'>
                    <strong className="text-dark">Reliability:</strong> Consistent performance and high uptime.
                  </li>
                  <li className='aboutli'>
                    <strong className="text-dark">User-Friendly:</strong> Easy-to-use platform for traders of all levels.
                  </li>
                  <li className='aboutli'>
                    <strong className="text-dark">24/7 Support:</strong> Always here to help you succeed.
                  </li>
                  <li className='aboutli'>
                    <strong className="text-dark">Security:</strong> Your data and transactions are protected with top-notch security measures.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_about mt-3">
                <img
                  src={stockmarket}
                  alt="stockmarket"
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Tradingabout;
