import React from "react";
import './Tradingtestimonials.scss';

function Tradingtestimonials() {

  return (
    <div className="testimonial-container">
      <h1>Testimonials</h1>
      <p>See what our satisfied clients have to say about our services!</p>
      <div className="testimonial-scroller">
        <figure className="snip1390">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample6.jpg" alt="profile-sample3" className="profile" />
          <figcaption>
            <h2>Diya Mishra</h2>
            <h4>UX Design</h4>
            <blockquote>The platform Stock Adv insights are top-notch. Highly recommended!</blockquote>
          </figcaption>
        </figure>
        <figure className="snip1390">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" className="profile" />
          <figcaption>
            <h2>Suraj Kumar</h2>
            <h4>Accountant</h4>
            <blockquote>Algorithmic trading has never been easier. Great service!</blockquote>
          </figcaption>
        </figure>
        <figure className="snip1390">
          <img src="https://img.freepik.com/free-photo/front-view-smiley-man-posing_23-2149512425.jpg?w=996&t=st=1718185267~exp=1718185867~hmac=39176d415e4dba9e439610f725deb2b443e04f5a6382b14d87755c18f54c884f" alt="profile-sample6" className="profile" />
          <figcaption>
            <h2>Piyush Patel</h2>
            <h4>Public Relations</h4>
            <blockquote>Accurate Trading Signals have boosted my profits. Fantastic platform!</blockquote>
          </figcaption>
        </figure>
      </div>
    </div>
  );
}

export default Tradingtestimonials;