import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import "./Dashboard_Main.scss";
import Side_bar from "../dashboard_sidebar/Side_bar";
import Cookies from "universal-cookie";
import Pie_Chart from "../pie-chart/Pie_Chart";
import axios from "axios";
import ProfileMenu from "../profile/ProfileMenu";
import NotificationMenu from "../notification/Notification"; // Import your NotificationMenu component

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Dashboard_Main() {
  const cookies = new Cookies();
  const username = cookies.get("username");
  const userId = cookies.get("id"); // Get user ID from cookies
  const [investmentData, setInvestmentData] = useState([]);
  const [currentValueData, setCurrentValueData] = useState([]);
  const [fundInvestedData, setFundInvestedData] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // const [filteredData, setFilteredData] = useState([]);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const toggleNotificationMenu = () => {
    setIsNotificationVisible(!isNotificationVisible);
  };

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!userId) {
          console.error("User ID not found.");
          return;
        }

        const imageUrl = `https://www.upholic.in/Upholic_Api/uploads/${encodeURIComponent(
          userId
        )}_profile.png?timestamp=${new Date().getTime()}`;
        // console.log("Image URL:", imageUrl); // Debugging statement

        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/imageget.php",
          {
            params: { url: imageUrl },
            responseType: "blob",
          }
        );

        if (response.status === 200) {
          const imageUrlBlob = URL.createObjectURL(response.data);
          setImageSrc(imageUrlBlob);
          // console.log("Image Src:", imageUrlBlob); // Debugging statement
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching the image:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };

    fetchImage();
  }, [userId]);

  console.log("Image Src:", imageSrc);

  useEffect(() => {
    async function fetchData() {
      try {
        const investmentRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/pms.php?action=investment&user_id=${userId}`
        );
        // console.log("Investment Response:", investmentRes.data);
        setInvestmentData(investmentRes.data);

        const currentValueRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/pms.php?action=current_value&user_id=${userId}`
        );
        // console.log("Current Value Response:", currentValueRes.data);
        setCurrentValueData(currentValueRes.data);

        const fundInvestedRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/pms.php?action=fund_invested&user_id=${userId}`
        );
        // console.log("Fund Invested Response:", fundInvestedRes.data);
        setFundInvestedData(fundInvestedRes.data);

        const portfolioRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/pms.php?action=portfolio&user_id=${userId}`
        );
        // console.log("Portfolio Response:", portfolioRes.data);
        setPortfolioData(portfolioRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const totalCurrentAmount =
    currentValueData.length > 0 ? parseFloat(currentValueData[0].amount) : 0;
  const formattedAmount = totalCurrentAmount.toFixed(2); // Assuming you want 2 decimal places

  useEffect(() => {
    async function fetchChartData() {
      try {
        const chartRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/chartline.php?user_id=${userId}`
        );
        if (Array.isArray(chartRes.data)) {
          setChartData(chartRes.data);
        } else {
          console.error("Invalid chart data format:", chartRes.data);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    }

    fetchChartData();
  }, [userId]);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Initialize dataPoints with all months and set y to null by default
  let dataPoints = monthNames.map((month, index) => ({
    x: new Date(2024, index, 1), // Setting each month correctly
    y: null,
    indexLabel: "",
  }));

  // Update dataPoints with actual data
  chartData.forEach((dataPoint) => {
    const monthIndex = monthNames.indexOf(dataPoint.month_name);
    if (monthIndex !== -1) {
      dataPoints[monthIndex].y = parseFloat(dataPoint.amount);
      dataPoints[monthIndex].indexLabel = `₹${dataPoint.amount}`;
    } else {
      console.error("Invalid month name:", dataPoint.month_name);
    }
  });

  // Remove data points before the first data point with y !== null
  const firstDataIndex = dataPoints.findIndex((point) => point.y !== null);
  dataPoints = dataPoints.slice(firstDataIndex);

  const options = {
    animationEnabled: true,
    exportEnabled: false,
    theme: "light2",
    backgroundColor: "#f9f9f9",
    axisX: {
      title: "Months",
      titleFontColor: "#555",
      titleFontSize: 16,
      valueFormatString: "MMM",
      interval: 1,
      intervalType: "month",
      labelFontColor: "#555",
      labelFontFamily: "Arial",
      tickColor: "#ccc",
      lineColor: "#ccc",
    },
    axisY: {
      title: "Amount (₹)",
      titleFontColor: "#555",
      titleFontSize: 16,
      labelFontColor: "#555",
      labelFontFamily: "Arial",
      gridColor: "#eee",
      tickColor: "#ccc",
    },
    data: [
      {
        yValueFormatString: "₹#,###",
        xValueFormatString: "MMMM",
        type: "splineArea",
        color: "#4F81BC",
        fillOpacity: 0.7,
        lineThickness: 3,
        dataPoints: dataPoints,
        markerSize: 8,
        markerColor: "#4F81BC",
        markerBorderColor: "#333",
        markerBorderThickness: 2,
      },
    ],
    toolTip: {
      shared: true,
      contentFormatter: function (e) {
        return `${e.entries[0].dataPoint.x.toLocaleString("default", {
          month: "short",
        })}: ₹${e.entries[0].dataPoint.y}`;
      },
    },
    legend: {
      fontColor: "#555",
      fontFamily: "Arial",
      fontWeight: "normal",
    },
    credits: {
      enabled: false,
    },
    chartArea: {
      background: "#ffffff",
      borderColor: "#ddd",
      borderWidth: 1,
    },
  };

  // Adjust font sizes for phone view
  if (window.innerWidth <= 768) {
    options.axisX.labelFontSize = 8;
    options.axisY.labelFontSize = 9;
  }

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(e.target.value);
    setSearchActive(true);
    console.log("Search query:", value); // Debug: Log the search query
    setSearchQuery(value);
  };

  const filteredData = fundInvestedData.filter((transaction) => {
    // Assuming transaction.stock is the field you want to filter by
    return transaction.stock.toLowerCase().includes(searchQuery.toLowerCase());
  });

  console.log("Filtered Data:", filteredData); // Debug: Log the filtered data

  const handleSearchFinish = () => {
    setSearchActive(false);
    setSearchQuery(""); // Clear search query when search is finished
    // Perform actions when search is finished
  };

  document.addEventListener("DOMContentLoaded", () => {
    const accordionToggles = document.querySelectorAll(".accordion-toggle");

    accordionToggles.forEach((toggle) => {
      toggle.addEventListener("click", () => {
        const content = toggle.nextElementSibling;
        const isOpen = content.classList.contains("show");

        document.querySelectorAll(".accordion-content").forEach((c) => {
          c.classList.remove("show");
          c.style.maxHeight = null;
        });

        document.querySelectorAll(".accordion-toggle").forEach((t) => {
          t.classList.remove("active");
        });

        if (!isOpen) {
          content.classList.add("show");
          content.style.maxHeight = content.scrollHeight + "px";
          toggle.classList.add("active");
        }
      });
    });
  });

  // Update the isMobile state on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="dashoard-main-div">
        <Side_bar />
        <main className="main-content">
          <div className="top-container">
            <div className="search">
              <svg
                class="search__icon"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5418 19.25C15.3513 19.25 19.2502 15.3512 19.2502 10.5417C19.2502 5.73223 15.3513 1.83337 10.5418 1.83337C5.73235 1.83337 1.8335 5.73223 1.8335 10.5417C1.8335 15.3512 5.73235 19.25 10.5418 19.25Z"
                  stroke="#596780"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.1668 20.1667L18.3335 18.3334"
                  stroke="#596780"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <input
                type="text"
                className="search__input"
                placeholder="Search Your Stock Name"
                value={searchQuery}
                onChange={handleSearchChange}
                onBlur={handleSearchFinish} // Optional: handle search finish on blur
              />
            </div>
            <div className="user-nav">
              <button className="notification" onClick={toggleNotificationMenu}>
                {" "}
                <svg
                  class="notification__icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0201 2.91003C8.71009 2.91003 6.02009 5.60003 6.02009 8.91003V11.8C6.02009 12.41 5.76009 13.34 5.45009 13.86L4.30009 15.77C3.59009 16.95 4.08009 18.26 5.38009 18.7C9.69009 20.14 14.3401 20.14 18.6501 18.7C19.8601 18.3 20.3901 16.87 19.7301 15.77L18.5801 13.86C18.2801 13.34 18.0201 12.41 18.0201 11.8V8.91003C18.0201 5.61003 15.3201 2.91003 12.0201 2.91003Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  />
                </svg>
              </button>
              <div className="user-info" onClick={toggleMenu}>
                <div className="circle cricle_body">
                  {imageSrc ? (
                    <img src={imageSrc} alt="profile" />
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>

                <span className="user-name">{username}</span>
              </div>
              <ProfileMenu
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
              />
              <NotificationMenu
                isVisible={isNotificationVisible}
                onClose={() => setIsNotificationVisible(false)}
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5999 7.45837L11.1666 12.8917C10.5249 13.5334 9.4749 13.5334 8.83324 12.8917L3.3999 7.45837"
                  stroke="#596780"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="bottom-container">
            {searchQuery === "" && (
              <div className="bottom-container__left">
                <div className="box box-size spending-box">
                  <div className="header-container">
                    <h3 className="section-header">Investment Growth</h3>
                    <div className="year-selector">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.8"
                          width="24"
                          height="24"
                          rx="6"
                          fill="#F6F7F9"
                        />
                        <path
                          d="M13.4999 15.96L10.2399 12.7C9.85492 12.315 9.85492 11.685 10.2399 11.3L13.4999 8.04004"
                          stroke="#1A202C"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span>2024</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.8"
                          width="24"
                          height="24"
                          rx="6"
                          fill="#F6F7F9"
                        />
                        <path
                          d="M10.4551 15.96L13.7151 12.7C14.1001 12.315 14.1001 11.685 13.7151 11.3L10.4551 8.04004"
                          stroke="#1A202C"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="bar-chart chart-container">
                    <CanvasJSChart options={options} />
                  </div>
                </div>

                <div className="investment-overview">
                  <div className="box total-box total-box__left">
                    <div className="header-container">
                      <h3 className="section-header">Your Investment</h3>
                    </div>
                    <h1 className="price">
                      ₹{investmentData[0] && investmentData[0].amount}.00
                      <span className="price-currency"></span>
                    </h1>
                  </div>

                  <div className="box total-box total-box__right">
                    <div className="header-container">
                      <h3 className="section-header">Current Value</h3>
                      {investmentData[0] && currentValueData[0] && (
                        <>
                          {parseFloat(investmentData[0].amount) >
                          parseFloat(currentValueData[0].amount) ? (
                            <svg
                              width="42"
                              height="42"
                              viewBox="0 0 42 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="42"
                                height="42"
                                rx="8"
                                fill="#F6F7F9"
                              />
                              <path
                                d="M27.0702 23.43L21.0002 29.5L14.9302 23.43"
                                stroke="#FF4423"
                                strokeWidth="2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 12.5V29.33"
                                stroke="#FF4423"
                                strokeWidth="2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="up-arrow"
                              width="42"
                              height="42"
                              viewBox="0 0 42 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="42"
                                height="42"
                                rx="8"
                                fill="#F6F7F9"
                              />
                              <path
                                d="M27.0702 18.57L21.0002 12.5L14.9302 18.57"
                                stroke="#006400"
                                strokeWidth="2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 29.5V12.67"
                                stroke="#006400"
                                strokeWidth="2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </>
                      )}
                    </div>
                    <div className="price-container">
                      <h1 className="price">
                        ₹{formattedAmount}
                        <span className="price-currency"></span>
                      </h1>
                      <p className="overall-growth">
                        <span
                          className={`percentage-increase ${
                            currentValueData[0] &&
                            currentValueData[0].increase >= 0
                              ? "positive"
                              : "negative"
                          }`}
                        >
                          {currentValueData[0] &&
                          currentValueData[0].increase !== undefined
                            ? `${currentValueData[0].increase}%`
                            : ""}
                        </span>{" "}
                        Overall Growth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {searchQuery === "" && (
              <div className="bottom-container__right">
                <div className="box spending-box">
                  <div className="header-container">
                    <h3 className="section-header">Your Portfolio</h3>
                  </div>
                  <div className="pie-chart">
                    <Pie_Chart />
                  </div>
                  <div className="overall-spending">
                    <h4>Current Amount</h4>
                    <span> ₹{formattedAmount}</span>
                  </div>
                  <div className="pie-chart__labels">
                    {Array.isArray(fundInvestedData) &&
                      fundInvestedData.map((stock, index) => (
                        <div className="pie-chart__labels-item" key={index}>
                          <div className="label">
                            <div className={`label__color ${index + 1}`}></div>
                            {stock.stock}
                          </div>
                          <div className="stockmoney">
                            ₹{stock.value_at_current_market_price}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="bottom-center">
            <div className="box transaction-box">
              <div className="header-container">
                <h3 className="section-header">Fund Invested</h3>
              </div>
              <table className="transaction-history">
                <thead>
                  <tr>
                    <th>
                      Stocks{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Sector <br />
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Quantity{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Average Price{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Value of Cost{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Percentage Changes{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Current Market Price{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Value at Current Market Price{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Unrealized PNL{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th>
                      Realized PNL{" "}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                          stroke="#90A3BF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData.map((transaction, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className="transaction-row"
                        onClick={() => handleToggle(index)}
                      >
                        <td className="transaction-cell">
                          {transaction.stock}
                          <svg
                            className={`accordion-icon ${
                              activeIndex === index ? "active" : ""
                            }`}
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.96004 4.47498L6.70004 7.73498C6.31504 8.11998 5.68504 8.11998 5.30004 7.73498L2.04004 4.47498"
                              stroke="#90A3BF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </td>
                        {!isMobile && (
                          <>
                            <td>{transaction.sector}</td>
                            <td>{transaction.quantity}</td>
                            <td>₹{transaction.average_price}</td>
                            <td>₹{transaction.value_of_cost}</td>
                            <td>{transaction.percentage_changes}%</td>
                            <td>₹{transaction.current_market_price}</td>
                            <td>
                              ₹{transaction.value_at_current_market_price}
                            </td>
                            <td>₹{transaction.unrealizedPNL}</td>
                            <td>₹{transaction.realizedPNL}</td>
                          </>
                        )}
                      </tr>
                      {isMobile && activeIndex === index && (
                        <tr className="accordion-details">
                          <div className="accordion-content">
                            <table className="accordion-table">
                              <tbody>
                                <tr>
                                  <td>{transaction.stock}</td>
                                  <td>{transaction.sector}</td>
                                  <td>{transaction.quantity}</td>
                                  <td>₹{transaction.average_price}</td>
                                  <td>₹{transaction.value_of_cost}</td>
                                  <td>{transaction.percentage_changes}%</td>
                                  <td>₹{transaction.current_market_price}</td>
                                  <td>
                                    ₹{transaction.value_at_current_market_price}
                                  </td>
                                  <td>₹{transaction.unrealizedPNL}</td>
                                  <td>₹{transaction.realizedPNL}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Dashboard_Main;
