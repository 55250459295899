import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUsers, faPhone } from "@fortawesome/free-solid-svg-icons"; // Import the specific icons you need
import "./About.scss";
import about from "../../assets/images/about.jpg";

function About() {

  return (
    <>
      {/* <!-- About Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 align-items-end mb-4">
            <div className="col-lg-6 wow fadeInUp">
              <img className="img-fluid rounded" src={about} alt="About Us" />
            </div>
            <div className="col-lg-6 wow fadeInUp">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                About Us
              </p>
              <h1 className="display-5 mb-4">
                We Help Our Clients To Grow Their Wealth
              </h1>
              <p className="mb-4">
                Our mission is to empower our clients to maximize the potential
                of their financial resources and achieve their long-term wealth
                goals. Through personalized financial planning, prudent
                investment strategies, and ongoing guidance, we provide the
                tools and expertise necessary to cultivate and protect our
                clients' assets.
              </p>
              <div className="border rounded p-4">
                <nav>
                  <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <button
                      className="nav-link fw-semi-bold active"
                      id="nav-story-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-story"
                      type="button"
                      role="tab"
                      aria-controls="nav-story"
                      aria-selected="true"
                    >
                      Story
                    </button>
                    <button
                      className="nav-link fw-semi-bold"
                      id="nav-mission-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-mission"
                      type="button"
                      role="tab"
                      aria-controls="nav-mission"
                      aria-selected="false"
                    >
                      Mission
                    </button>
                    <button
                      className="nav-link fw-semi-bold"
                      id="nav-vision-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-vision"
                      type="button"
                      role="tab"
                      aria-controls="nav-vision"
                      aria-selected="false"
                    >
                      Vision
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-story"
                    role="tabpanel"
                    aria-labelledby="nav-story-tab"
                  >
                    <p>
                      Our journey began with a simple yet powerful belief: that
                      everyone deserves the opportunity to build and grow their
                      wealth. Inspired by this vision, we set out on a mission
                      to empower individuals and families with the tools and
                      resources they need to achieve financial prosperity.
                    </p>
                    <p className="mb-0">
                      Driven by our passion for helping others succeed, we
                      rolled up our sleeves and got to work. We listened to our
                      clients' needs, understood their goals, and developed
                      tailored solutions to meet their individual requirements.
                      Through our dedication, expertise, and unwavering
                      commitment to excellence, we began to see our clients
                      thrive and prosper.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-mission"
                    role="tabpanel"
                    aria-labelledby="nav-mission-tab"
                  >
                    <p>
                      Our mission is clear: to help our clients grow their
                      wealth and achieve financial freedom. We are dedicated to
                      providing personalized guidance, innovative strategies,
                      and exceptional service to empower our clients to reach
                      their financial goals.
                    </p>
                    <p className="mb-0">
                      We believe that by fostering a culture of trust,
                      transparency, and integrity, we can build lasting
                      relationships with our clients and help them navigate the
                      complexities of the financial landscape with confidence.
                      Whether it's through prudent investment strategies,
                      comprehensive financial planning, or ongoing support and
                      education, we are committed to being a trusted partner on
                      our clients' journey to success.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-vision"
                    role="tabpanel"
                    aria-labelledby="nav-vision-tab"
                  >
                    <p>
                      Our vision is to be the premier destination for
                      individuals and families seeking to build and preserve
                      their wealth for generations to come. We aspire to be
                      recognized not only for our financial expertise but also
                      for our unwavering commitment to our clients' success and
                      well-being.
                    </p>
                    <p className="mb-0">
                      We envision a future where every client feels empowered to
                      take control of their financial future, where wealth is
                      not just a privilege for the few, but a possibility for
                      all who dare to dream. Through our relentless pursuit of
                      excellence and our dedication to serving our clients with
                      integrity and passion, we aim to make this vision a
                      reality, one client at a time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border rounded p-4 wow fadeInUp">
            <div className="row g-4">
              <div className="col-lg-4 wow fadeIn">
                <div className="h-100">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <FontAwesomeIcon icon={faTimes} className="text-white" />
                    </div>
                    <div className="ps-3">
                      <h4>No Hidden Cost</h4>
                      <span>
                        Transparency is at the core of our approach, fostering
                        openness and trust.
                      </span>
                    </div>
                    <div className="border-end d-none d-lg-block"></div>
                  </div>
                  <div className="border-bottom mt-4 d-block d-lg-none"></div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="h-100">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <FontAwesomeIcon icon={faUsers} className="text-white" />
                    </div>
                    <div className="ps-3">
                      <h4>Dedicated Team</h4>
                      <span>
                        Dedicated team of professionals committed to serving our
                        clients with excellence.
                      </span>
                    </div>
                    <div className="border-end d-none d-lg-block"></div>
                  </div>
                  <div className="border-bottom mt-4 d-block d-lg-none"></div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="h-100">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <FontAwesomeIcon icon={faPhone} className="text-white" />
                    </div>
                    <div className="ps-3">
                      <h4>24/7 Available</h4>
                      <span>
                        Proud to offer around-the-clock availability for our
                        clients.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}
    </>
  );
}

export default About;
